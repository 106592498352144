import { display } from "redux-react/reducers/snackBarReducer";
import { api } from "./api";

const actions = {
	getTemplateCountFromProcess: (onSuccess, onFailure = () => {}) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/promptstudio/templates/process/count`,
					onSuccess,
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error ?? "none"
							})
						);
					}
				})
			);
		};
	},
	startPlayground: (data, onSuccess, onFailure = () => {}) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/promptstudio/playground/start`,
					data,
					onSuccess,
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error ?? "none"
							})
						);
					}
				})
			);
		};
	}
};

export default actions;

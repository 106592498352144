import { useState, useEffect } from "react";
import { Fade, Grow, Avatar } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import i18n from "i18n";
import MDInput from "components/Basics/MDInput";
import lod_ from "lodash";
import MiniInfoCard from "components/Advanced/Cards/InfoCards/MiniInfoCard";
import DefaultInfoCard from "components/Advanced/Cards/InfoCards/DefaultInfoCard";
import InformationCardUser from "../components/InformationCardUser";

/**
 * Step 2: Settings profile
 */
const SkillReview = ({ profile, skill, assistantID, assistantName, validStep }) => {
	useEffect(() => {
		// check if there is at least 1 profile and if the profiles in the list have a userID, assistantID and role
		if (
			profile.length > 0 &&
			profile.every(profile => profile.userID && profile.assistantID && profile.role)
		) {
			// also sort the profiles alphabetically
			profile.sort((a, b) => (a.userID > b.userID ? 1 : -1));
			validStep(true);
		}
	}, []);

	// this is to make the image in the DefaultInfoCard for profile be an icon
	let avatar = false;
	return (
		// create a line with the assistantID in it to indicate in wich assistant we're working
		<>
			<MDBox display="flex" flexDirection="column" justifyContent="space-evenly">
				<MDTypography variant="h4" color="dark" textAlign="center">
					{i18n.t("SETTINGS.PROFILE.assistantName")}
					{assistantName}
				</MDTypography>
			</MDBox>
			<MDBox mt={3} display="flex" flexDirection="row" justifyContent="space-evenly">
				<MDBox>
					<MDTypography variant="h4" color="dark" textAlign="center">
						{i18n.t("SETTINGS.SKILLS.skillReviewTitle")}
					</MDTypography>
					<DefaultInfoCard
						icon="show_chart"
						title={
							<MDBox display="flex" justifyContent="center">
								<MDTypography variant="h5" color="dark">
									{i18n.t("SETTINGS.SKILLS.name")} :&nbsp;
								</MDTypography>
								<MDTypography variant="h6" color="text">
									{i18n.t(skill.name)}
								</MDTypography>
							</MDBox>
						}
						value={
							<MDBox display="flex" justifyContent="center">
								<MDTypography variant="h5" color="dark">
									{i18n.t("SETTINGS.SKILLS.description")} :&nbsp;
								</MDTypography>
								<MDTypography variant="h6" color="text">
									{i18n.t(skill.description)}
								</MDTypography>
							</MDBox>
						}
					/>
				</MDBox>
				<MDBox>
					<MDTypography variant="h4" color="dark" textAlign="center">
						{i18n.t("SETTINGS.PROFILE.skillReviewTitle")}
					</MDTypography>
					{profile.length === 1 ? (
						<DefaultInfoCard
							icon={
								<Avatar
									style={{ width: 100, height: 100, objectFit: "cover" }}
									alt="profile"
									src={profile[0].picture}
								/>
							}
							title={
								<MDBox display="flex" justifyContent="center">
									<MDTypography variant="h5" color="dark">
										{i18n.t("SETTINGS.PROFILE.fullName")} :&nbsp;
									</MDTypography>
									<MDTypography variant="h6" color="text">
										{i18n.t(profile[0].first)}&nbsp;
										{i18n.t(profile[0].last)}
									</MDTypography>
								</MDBox>
							}
							value={
								<MDBox display="flex" justifyContent="center">
									<MDTypography variant="h5" color="dark">
										{i18n.t("SETTINGS.PROFILE.userID")} :&nbsp;
									</MDTypography>
									<MDTypography variant="h6" color="text">
										{i18n.t(profile[0].userID)}
									</MDTypography>
								</MDBox>
							}
							avatar={avatar}
						/>
					) : (
						<MDBox mt={3} display="flex" flexDirection="column" justifyContent="space-evenly">
							<>
								{profile.map((c, index) => {
									return (
										<Grow key={index} in timeout={(index + 1) * 500}>
											<MDBox display="flex">
												<MDBox flex="1">
													<InformationCardUser element={c} />
												</MDBox>
											</MDBox>
										</Grow>
									);
								})}
							</>
						</MDBox>
					)}
				</MDBox>
			</MDBox>
		</>
	);
};

export default SkillReview;

import { useFAibrikContext } from "context/ProvideAuthFaibrik";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";
import lod_ from "lodash";
import { CircularProgress } from "@mui/material";
import ErrorPage from "pages/error";
import MDBox from "components/Basics/MDBox";
import { useAuth0 } from "./react-auth0-wrapper";

const PrivateRoute = ({ component: Component, path, ...rest }) => {
	const { loading, isAuthenticated, loginWithRedirect, user, getTokenSilently } = useAuth0();
	const { fAibrikStatus } = useFAibrikContext();
	const state = useSelector(state => state);

	/**
	 * Auth0 connection
	 */
	useEffect(() => {
		if (loading || isAuthenticated) {
			return;
		}
		const fn = async () => {
			//			actions.setTheAuth(useAuth0);
			await loginWithRedirect({
				appState: { targetUrl: path }
			});
		};
		fn();
	}, [loading, isAuthenticated, loginWithRedirect, path]);

	/**
	 * When user is authentificated, get the token and fetch the user data
	 */
	React.useEffect(() => {
		if (isAuthenticated && user) {
			getTokenSilently().then(token => {
				user.token = token;
				fAibrikStatus.fetching(user);
			});
		}
	}, [isAuthenticated, user]);

	if (state.error?.code) {
		return (
			<ErrorPage code={state.error.code} message={state.error.message} status={state.error.status} />
		);
	}

	/**
	 * When datas are loaded display the main component
	 */
	if (!lod_.isEmpty(state.user) /* && !lod_.isEmpty(state.assistants) */) {
		return <Component />;
	} else {
		return (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					width: "100%",
					height: "100vh"
				}}
			>
				<CircularProgress color="dark" />
			</div>
		);
	}
};

export default PrivateRoute;

/* eslint-disable prefer-destructuring */
/* eslint-disable no-plusplus */
import { createSlice } from "@reduxjs/toolkit";
import notificationSound from "assets/sounds/notification.mp3";

const initialState = {
	// list: []
};

export const snackBar = initialState;

const randomCode = () => {
	let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let length = 8;
	let code = [];

	for (let i = 0; i < length; i++) {
		code.push(chars.charAt(Math.floor(Math.random() * chars.length)));
	}

	return code.join("");
};

const slice = createSlice({
	name: "notifications",
	initialState,
	reducers: {
		viewedAllNotifications: (state, action) => {
			let assistantID = action.payload.assistantID;
			if (!state[assistantID] || !state[assistantID].list) return;

			state[assistantID].list = state[assistantID]?.list.map(notification => {
				return { ...notification, viewed: true };
			});
		},
		addNotification: (state, action) => {
			let assistantID = action.payload.assistantID;

			let notification = {
				id: randomCode(),
				icon: action.payload.icon,
				message: action.payload.message,
				ts: action.payload.ts,
				type: action.payload.type,
				viewed: false
			};

			if (!state[assistantID]) {
				state[assistantID] = {
					list: [notification]
				};
			} else {
				state[assistantID].list.push(notification);
			}

			// play notification sound
			// Disable sound for now
			// let audio = new Audio(notificationSound);
			// audio.play();
		},
		removeNotification: (state, action) => {
			let assistantID = action.payload.assistantID;
			state[assistantID].list = state[assistantID]?.list.filter(
				notification => notification.id !== action.payload.id
			);
		}
	}
});

export const selectNotifications = state => state.notifications;

export const { addNotification, viewedAllNotifications, removeNotification } = slice.actions;

export default slice.reducer;

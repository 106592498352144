const { Icon, MenuItem, Checkbox, Tooltip } = require("@mui/material");
const { default: MDBox } = require("components/Basics/MDBox");
const { default: MDTypography } = require("components/Basics/MDTypography");

/**
 * Render a channel option in the autocomplete
 * @returns
 */
const ChannelOptionRender = ({ props, option }) => {
	let selected = props["aria-selected"];

	return (
		<MenuItem {...props} sx={{ mt: 0.5 }}>
			<Checkbox checked={selected} />
			<MDBox display="flex" alignItems="center">
				{option.logoURL && (
					<MDBox
						component="img"
						src={option.logoURL}
						alt={option.name}
						style={{
							width: "30px",
							height: "30px",
							// borderRadius: "50%",
							objectFit: "contain"
						}}
					/>
				)}
				{option.icon && (
					<Icon
						fontSize="large"
						sx={{
							width: "40px",
							height: "40px"
						}}
					>
						{option.icon}
					</Icon>
				)}
				<MDBox sx={{ ml: 2 }}>
					<MDTypography variant="h6">{option.name}</MDTypography>
					<MDTypography variant="body2">{option.description ?? ""}</MDTypography>
				</MDBox>
			</MDBox>
		</MenuItem>
	);
};

export default ChannelOptionRender;

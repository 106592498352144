/* eslint-disable no-unreachable */
import "../../style.css";
import { CircularProgress } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import FormAction from "redux-react/actions/formAction";
import SettingsActions from "redux-react/actions/settingsActions";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { LittleForm } from "components/Custom/LittleForm";
import i18n from "i18n";
import { createDictionarySkeleton } from "helpers/form";

/**
 * Step 1: Selected the profile type
 */
const Step2CommonPart = ({
	validStep,
	onChange,
	channel,
	common,
	setCommon,
	errorUniqueFields,
	dictionaryChannelName
}) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const [dictionary, setDictionary] = useState({});
	const [configSkeleton, setConfigSkeleton] = useState({});

	useEffect(() => {
		setConfigSkeleton(common);
	}, [common]);

	useEffect(() => {
		dispatch(
			SettingsActions.getDictionaries({ query: {}, catalog: "channel" }, res => {
				let commonDictionary = res.dictionaries[dictionaryChannelName] ?? {};
				setDictionary(commonDictionary.items ?? {});

				let buildedCommon = createDictionarySkeleton(commonDictionary ?? {}, common);
				setConfigSkeleton(buildedCommon);
				setCommon(buildedCommon);

				setLoading(false);
				validStep();
			})
		);
	}, []);

	return (
		<MDBox sx={{ height: "100%", width: "100%" }} display="flex" justifyContent="center">
			<MDBox sx={{ height: "100%", width: "60%" }} pt={5}>
				<MDBox display="flex" alignItems="center" pb={2}>
					<MDBox
						sx={{
							height: "3rem",
							width: "3rem"
						}}
						mr={1}
					>
						<MDBox
							component="img"
							borderRadius="md"
							src={channel.logoURL}
							alt={channel.logoURL}
							sx={{
								width: "100%",
								height: "auto",
								objectFit: "contain"
							}}
						/>
					</MDBox>

					<MDTypography variant="h4">{`${i18n.t("CHANNEL.configureChannel")} ${channel.name ? channel.name : ""}`}</MDTypography>
				</MDBox>

				{loading ? (
					<MDBox
						display="flex"
						alignItems="center"
						justifyContent="center"
						sx={{
							height: "auto",
							width: "100%"
						}}
					>
						<CircularProgress color="info" />
					</MDBox>
				) : (
					<LittleForm
						object={dictionary}
						metadatasSkeleton={configSkeleton}
						errorUniqueFields={errorUniqueFields}
						handleChange={(path, value) => {
							onChange(path, value);
						}}
					/>
				)}
			</MDBox>
		</MDBox>
	);
};

export default Step2CommonPart;

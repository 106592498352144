import { Icon } from "@mui/material";
import MDBadge from "components/Basics/MDBadge";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import i18n from "i18n";

const LlmModelList = ({ model, dbModel, llmDocumentType }) => {
	return (
		<MDBox display="flex" flexDirection="column" alignItems="stretch">
			<MDBox display="flex" alignItems="center" justifyContent="space-between" flex="1">
				<MDBox display="flex" alignItems="center" justifyContent="space-between" flex="1">
					<MDBox display="flex" alignItems="center" flex="1">
						<MDBox mr={1} display="flex" alignItems="center">
							<img src={dbModel?.image} alt="AzureOpenAI" width="20" />
						</MDBox>
						<MDTypography variant="h6">{model.name}</MDTypography>
					</MDBox>
				</MDBox>
			</MDBox>
			<MDBadge
				circular
				variant="contained"
				size="xs"
				badgeContent={llmDocumentType?.name}
				container
			></MDBadge>

			<MDTypography variant="body2">
				{model.description || i18n.t("PROMPT.MODEL.noDescription")}
			</MDTypography>
		</MDBox>
	);
};

export default LlmModelList;

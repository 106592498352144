/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import { setError } from "redux-react/reducers/errorReducer";
import { display } from "redux-react/reducers/snackBarReducer";
import { api } from "./api";

const actions = {
	updateRule: (assistantID, code, rule, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "PUT",
					url: `/api/v1/rule/${assistantID}/${code}`,
					data: { rule },
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error?.message ?? "none"
							})
						);
					}
				})
			);
		};
	},
	updateSettings: (assistantID, collection, settings, id, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "PUT",
					url: `/api/v1/update/settings/${id}`,
					data: { collection, settings, assistantID },
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error?.message ?? "none"
							})
						);
					}
				})
			);
		};
	},
	updateAttributeDisplayPaginedList: (listCode, attributesList, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "PUT",
					url: `/api/v1/updateAttributeDisplayPaginedList/${listCode}`,
					data: { attributesList },
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error ?? "none"
							})
						);
					}
				})
			);
		};
	},
	getPageFilters: (assistantID, code, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/filters/${assistantID}/${code}`,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error?.message ?? "none"
							})
						);
					}
				})
			);
		};
	},
	getChartByCode: (assistantID, code, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/chart/${assistantID}/${code}`,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(setError(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	refreshPaginedList: (
		list,
		entriesParPage,
		page,
		search,
		sort,
		filters,
		profileSystem,
		onSuccess
	) => {
		let data = {
			entriesParPage,
			page,
			search,
			request: list.request,
			filters,
			sort,
			profileSystem
		};
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/paginedList/pagination`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(setError(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	getPaginedList: (assistantID, route, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/paginedList/${assistantID}/${route.route.replace("/", "")}`,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(setError(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	getCharts: (assistantID, route, onSuccess) => {
		let data = {
			filter: route.filter
		};
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/charts/${assistantID}/${route.route.replace("/", "")}`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(setError(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	buildRawCharts: (charts, filters, mandatoryFilters, onSuccess) => {
		let data = {
			charts,
			filters,
			mandatoryFilters: mandatoryFilters ?? []
		};

		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: "/api/v1/charts",
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(setError(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	getPageCharts: (assistantID, route, filters, mandatoryFilters, onSuccess) => {
		let data = {
			filters,
			mandatoryFilters: mandatoryFilters ?? []
		};

		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/pageCharts/${assistantID}/${route.replace("/", "")}`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error?.message ?? "none"
							})
						);
					}
				})
			);
		};
	}
};

export default actions;

/* eslint-disable import/prefer-default-export */
/**
 * Different type of message
 */
const MESSAGE_TYPE = [
	{
		icon: "settings",
		code: "system",
		label: "Système"
	},
	{
		icon: "person",
		code: "human",
		label: "Humain"
	},
	{
		code: "ai",
		icon: "android",
		label: "IA"
	}
];
/**
 * Playground process states
 */
const PG_PROCESS_STATES = {
	WAITING: "waiting",
	RUNNING: "running",
	RESULT: "result",
	ERROR: "error",
	DONE: "done"
};
/**
 * Playground step display
 */
const PG_STEP_DISPLAY = {
	DATASET: "dataset",
	PROCESS: "process",
	OPTIONS: "options",
	RESULT: "result"
};
/**
 * Collections
 */
const COLLECTIONS = {
	llmModel: "llmModel",
	llmProcess: "llmProcess",
	promptTemplate: "promptTemplate",
	llmDocumentType: "llmDocumentType",
	llmModelCategory: "llmModelCategory",
	llmModelConfig: "llmModelConfig",
	llmModelOptions: "llmModelOptions",
	llmModelProvider: "llmModelProvider"
};

module.exports = {
	MESSAGE_TYPE,
	PG_PROCESS_STATES,
	PG_STEP_DISPLAY,
	COLLECTIONS
};

import { Chip, InputAdornment, Typography } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import { t } from "i18next";
import { useEffect, useState } from "react";
import SkillActions from "redux-react/actions/skillActions";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SearchIcon from "@mui/icons-material/Search";

import DoneIcon from "@mui/icons-material/Done";
import { TagsListProfil } from "components/Custom/TagList";
import { useDispatch } from "react-redux";
import MDInput from "components/Basics/MDInput";

const ProfilsChoice = ({ listSkills, child, label, value, onChangeList, disabled, title }) => {
	const [selected, setSelected] = useState([]);
	const dispatch = useDispatch();
	const [search, setSearch] = useState("");

	useEffect(() => {
		onChangeList(selected);
	}, [selected]);
	const isAllUsersChecked = selected.length === listSkills.length;
	const clickable = true;

	const toggleSelectAll = () => {
		if (isAllUsersChecked) {
			setSelected([]);
		} else {
			setSelected(listSkills);
		}
	};

	useEffect(() => {
		if (value) {
			const onSuccess = res => {
				setSelected(res.profiles);
			};

			dispatch(SkillActions.getProfilsBySkill(value, onSuccess));
		} else {
			setSelected([]);
		}
	}, []);

	/**
	 * Search for filter
	 * @param {*} event
	 */
	const handleSearchChange = event => {
		setSearch(event.target.value);
	};

	return (
		<MDBox>
			<MDBox display="flex" mt={2}>
				<Typography pr={1}>{title}</Typography>
				<Chip
					label={isAllUsersChecked ? t("FORMS.deselectAll") : t("FORMS.selectAll")}
					color={isAllUsersChecked ? "default" : "info"}
					deleteIcon={isAllUsersChecked ? <HighlightOffIcon /> : <DoneIcon />}
					clickable={clickable}
					onClick={() => toggleSelectAll()}
					onDelete={() => toggleSelectAll()}
				/>
			</MDBox>
			<MDBox display="flex" mt={1}>
				<MDInput
					fullWidth
					label=""
					variant="outlined"
					size="small"
					onChange={handleSearchChange}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						)
					}}
				>
					dd
				</MDInput>
			</MDBox>

			<TagsListProfil
				list={listSkills}
				labelField="userID"
				titleField="userID"
				valueField="userID"
				isForm
				selected={selected}
				setSelected={setSelected}
				isIntent
				disabled={disabled}
				search={search}
			/>
		</MDBox>
	);
};

export default ProfilsChoice;

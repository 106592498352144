/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-underscore-dangle */

/**
 * #######################################################@
 *
 * Skills chart settings
 *
 * #######################################################@
 */
import "./style.css";

import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Step,
	StepLabel,
	Stepper
} from "@mui/material";
import { useRef, useState, useEffect } from "react";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import i18n from "i18n";
import lod_, { set } from "lodash";

import { useSelector, useDispatch } from "react-redux";
import SkillActions from "redux-react/actions/skillActions";

import SkillEditorStepChooseProfil from "./steps/2. SkillEditorStepChooseProfil";
import SkillEditorStepEditSkill from "./steps/1. SkillEditorStepEditSkill";
import SkillEditorAssignationRule from "./steps/disabled. SkillEditorStepEditAssignRule";
import SkillReview from "./steps/3. SkillReview";

export default function AddSkillDialog({ open, handleCloseDialog, handleSave, itemToEdit = {} }) {
	const topContainer = useRef(null);
	// Product skeleton
	const [newProfiles, setNewProfiles] = useState([]);
	const [profilesRemovedFromSelection, setProfilesRemovedFromSelection] = useState([]);
	const [newSkill, setNewSkill] = useState({});
	const [skillToEdit, setSkillToEdit] = useState(itemToEdit);
	const [skillBeforeEditing, setSkillBeforeEditing] = useState({});
	const [skillHasBeenEdited, setSkillHasBeenEdited] = useState(false);
	const dispatch = useDispatch();

	// if skillToEdit is not an empty object, we set the skillBeforeEditing state to the skillToEdit to keep track of how it was before
	useEffect(() => {
		if (Object.keys(itemToEdit).length !== 0) {
			setSkillBeforeEditing(itemToEdit);
		}
	}, [itemToEdit]);
	/**
	 * Active step
	 */
	const [activeStep, setActiveStep] = useState(0);
	/**
	 *
	 * Steps labels
	 */
	const [steps, setSteps] = useState([
		i18n.t("SETTINGS.SKILLS.editSkill"),
		i18n.t("SETTINGS.SKILLS.chooseProfil"),
		i18n.t("SETTINGS.SKILLS.reviewSkill")
	]);
	/*
	 * Get assistantID from the profile in redux
	 */
	const assistantID = useSelector(state => state.profile?.assistantID);
	/**
	 * Get assistant Name from the assistant list in redux
	 */
	const assistantName = useSelector(state => state.profile?.selectedAssistant?.name);
	/**
	 * Can user go to next step
	 */
	const [stepValid, setStepValid] = useState(false);
	/**
	 * Is last step
	 */
	const isLastStep = activeStep === steps.length - 1;
	/**
	 * Close modale
	 */
	function close(e, reason) {
		handleCloseDialog();
		/**
		 * On component close, reset all states
		 * Timeout to wait for animation end
		 */
		setTimeout(() => {
			setNewProfiles({});
			setStepValid(false);
			setActiveStep(0);

			setSteps([
				i18n.t("SETTINGS.SKILLS.editSkill"),
				i18n.t("SETTINGS.SKILLS.chooseProfil"),
				i18n.t("SETTINGS.SKILLS.reviewSkill")
			]);
		}, 400);
	}

	// check if we are editing an item or not (we know we are editing if itemToEdit is not an empty object)
	function itemToEditExists() {
		if (Object.keys(itemToEdit).length !== 0) {
			return true;
		}
		return false;
	}

	/**
	 * Submit profile
	 */
	function sumbit() {
		// if we are editing a Skill, we need to pass the old Skill, the removed profiles, the new profiles and the new Skill
		// so we separate the two cases here
		if (itemToEditExists()) {
			handleSave(newProfiles, skillToEdit, skillBeforeEditing, profilesRemovedFromSelection);
			close();
		} else {
			// not editing a Skill
			handleSave(newProfiles, newSkill);
			close();
		}
	}

	/**
	 * User can go to next step
	 */
	const validStep = (val = true) => {
		setStepValid(val);
	};

	/**
	 * User select profile
	 */
	const handleSelectUser = user => {
		let copyProduct = lod_.cloneDeep(user);
		copyProduct.userID = user.userID;
		copyProduct.first = user.first;
		copyProduct.last = user.last;
		copyProduct.picture = user.picture;
		// check if the user is already in the list, if it is, remove it
		let index = newProfiles.findIndex(profile => profile.userID === user.userID);
		if (index !== -1) {
			let copy = [...newProfiles];
			copy.splice(index, 1);
			setNewProfiles(copy);
			// we do this to keep track of the profiles that were removed from the selection
			// only usefull when editing a Skill
			setProfilesRemovedFromSelection([...profilesRemovedFromSelection, copyProduct]);
			return;
		}
		setNewProfiles(prevNewProfiles => [...prevNewProfiles, copyProduct]);
	};

	const handleTypeSkillFields = skillInformations => {
		setNewSkill(skillInformations);
	};

	const handleTypeEditSkillFields = skillInformations => {
		setSkillToEdit(skillInformations);
		setSkillHasBeenEdited(true);
	};

	/**
	 * Get actual step content
	 */
	function getStepContent(stepIndex) {
		switch (stepIndex) {
			case 0:
				if (itemToEditExists()) {
					return (
						<SkillEditorStepEditSkill
							skill={skillToEdit}
							handleTypeSkillFields={handleTypeEditSkillFields}
							validStep={validStep}
						/>
					);
				} else {
					return (
						<SkillEditorStepEditSkill
							skill={newSkill}
							handleTypeSkillFields={handleTypeSkillFields}
							validStep={validStep}
						/>
					);
				}

			case 1:
				return (
					<SkillEditorStepChooseProfil
						profile={newProfiles}
						handleSelectUser={handleSelectUser}
						validStep={validStep}
						skillToCheckExistenceInprofile={skillBeforeEditing}
					/>
				);

			/* case 2:
				return (
					<SkillEditorAssignationRule
						profile={newProfiles}
						handleSelectRole={handleSelectRole}
						validStep={validStep}
					/>
				); */

			case 2:
				if (itemToEditExists()) {
					return (
						<SkillReview
							profile={newProfiles}
							skill={skillToEdit}
							assistantID={assistantID}
							assistantName={assistantName}
							validStep={validStep}
						/>
					);
				} else {
					return (
						<SkillReview
							profile={newProfiles}
							skill={newSkill}
							assistantID={assistantID}
							assistantName={assistantName}
							validStep={validStep}
						/>
					);
				}

			default:
				return null;
		}
	}
	/**
	 * Go to next step
	 */
	const handleNext = () => {
		if (activeStep === 0) {
			const onSuccess = res => {
				setActiveStep(activeStep + 1);
				setStepValid(false);
				topContainer?.current?.scrollIntoView();
			};
			if (itemToEditExists()) {
				setActiveStep(activeStep + 1);
				setStepValid(false);
				topContainer?.current?.scrollIntoView();
			} else {
				dispatch(SkillActions.checkSkillAlreadyExists("skill", newSkill, assistantID, onSuccess));
			}
		} else {
			setActiveStep(activeStep + 1);
			setStepValid(false);
			topContainer?.current?.scrollIntoView();
		}
	};
	/**
	 * Go to previous step
	 */
	const handleBack = () => {
		setActiveStep(activeStep - 1);
		topContainer?.current?.scrollIntoView();
	};

	/**
	 * Main component
	 */
	return (
		<Dialog fullWidth maxWidth="lg" open={open} onClose={close}>
			<DialogTitle>
				<MDBox>
					<Stepper activeStep={activeStep} alternativeLabel>
						{steps.map(label => (
							<Step key={label}>
								<StepLabel>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
				</MDBox>
			</DialogTitle>
			<DialogContent>
				<div data-id="top-container" ref={topContainer}></div>

				<MDBox mt={2}>{getStepContent(activeStep)}</MDBox>
			</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="dark" onClick={close}>
					{i18n.t("SETTINGS.cancel")}
				</MDButton>
				<MDButton disabled={activeStep === 0} variant="contained" color="light" onClick={handleBack}>
					{i18n.t("SETTINGS.CHARTS.NEW.STEPS.back")}
				</MDButton>
				<MDButton
					disabled={!stepValid}
					variant="contained"
					color="dark"
					onClick={!isLastStep ? handleNext : sumbit}
				>
					{isLastStep ? i18n.t("SETTINGS.add") : i18n.t("SETTINGS.CHARTS.NEW.STEPS.next")}
				</MDButton>
			</DialogActions>
		</Dialog>
	);
}

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import MDBox from "components/Basics/MDBox";

const InputCode = ({ label, value, onChange, codes, path }) => {
	return (
		<MDBox mt={1}>
			<FormControl fullWidth>
				<InputLabel id="select-label">{label}</InputLabel>
				<Select
					labelId="select-label"
					id="select"
					label={label}
					value={value || ""}
					name={path}
					onChange={e => {
						onChange(e.target.value);
					}}
				>
					{codes.map((code, index) => {
						return (
							<MenuItem key={index} value={code.value}>
								{code.label}
							</MenuItem>
						);
					})}
				</Select>
			</FormControl>
		</MDBox>
	);
};
export default InputCode;

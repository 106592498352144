import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import i18n from "i18n";
import FilterTableLine from "./FilterTableLine";
import C from "../constants";

const FiltersTable = ({ filtersArray, handleRemoveFilter, onChangeValue }) => {
	// Get all possibles methods for a given type
	function getMethod(type) {
		switch (type) {
			case "string":
				return C.COMP_STRING;
			case "number":
				return C.COMP_NUMBER;
			case "code":
				return C.COMP_CODE;
			case "date":
			case "datetime":
			case "timestamp":
				return C.COMP_DATE;
			default:
				return [];
		}
	}

	return (
		<TableContainer>
			<Table>
				<TableHead
					style={{
						display: "contents"
					}}
				>
					<TableRow>
						<TableCell style={{ width: "33%" }}>
							{i18n.t("SETTINGS.CHARTS.COMPUTE.TEXT.attribute")}
						</TableCell>
						<TableCell style={{ width: "33%" }}>
							{i18n.t("SETTINGS.CHARTS.COMPUTE.TEXT.method")}
						</TableCell>
						<TableCell style={{ width: "33%" }}>
							{i18n.t("SETTINGS.CHARTS.COMPUTE.TEXT.value")}
						</TableCell>
						<TableCell></TableCell>
						<TableCell></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{filtersArray.length === 0 && (
						<TableRow>
							<TableCell colSpan={5} align="center">
								{i18n.t("SETTINGS.CHARTS.COMPUTE.noFilters")}
							</TableCell>
						</TableRow>
					)}
					{filtersArray.map((filter, index) => {
						return (
							<FilterTableLine
								name={filter.name}
								value={filter.value}
								method={filter.method}
								filter={filter.filter}
								methods={getMethod(filter.filter.type)}
								key={index}
								handleRemoveFilter={handleRemoveFilter}
								onChangeValue={onChangeValue}
							/>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default FiltersTable;

const ANSWERS_FORMAT = {
	document: "document",
	menuyesno: "menuyesno",
	richtext: "richtext",
	signature: "signature",
	location: "location",
	image: "image",
	htmltext: "htmltext",
	video: "video",
	askemail: "askemail",
	menutext: "menutext"
};

const TYPE = {
	ALL: "ALL",
	conversational: "conversational",
	review: "review",
	ticket: "ticket"
};

const DOCUMENT_TYPE = {
	signature: "signature",
	answerType: "answerType"
};

module.exports = {
	ANSWERS_FORMAT,
	TYPE,
	DOCUMENT_TYPE
};

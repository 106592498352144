/* eslint-disable no-underscore-dangle */

/**
 * Main application customers
 */
import "./style.css";
import MDBox from "components/Basics/MDBox";
import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChartsActions from "redux-react/actions/chartsActions";
import FormActions from "redux-react/actions/formAction";
import SkillActions from "redux-react/actions/skillActions";
import _lod from "lodash";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon } from "@mui/material";
import i18n from "i18n";
import { useMaterialUIController } from "context";
import ChartsLoader from "components/Custom/ChartsLoader";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";
import MDButton from "components/Basics/MDButton";
import { display } from "redux-react/reducers/snackBarReducer";

import FormDictionaryDialog from "components/Custom/FormDictionary";
import AddSkillDialog from "pages/skills/AddSkillDialog";
import { getLocalStorageBackValues, parseFilters } from "components/Custom/Filters/filters";

const ButtonNewLabel = ({ handleClick, namePage }) => {
	return (
		<MDButton
			style={{ height: "100%", marginRight: "0.75rem" }}
			variant="contained"
			color="info"
			onClick={handleClick}
		>
			<Icon>add</Icon>&nbsp;{i18n.t("FORMS.LABELS.add") + namePage}
		</MDButton>
	);
};

/**
 *
 * @param {Object} route
 *  - key : actual page
 *  - type : type of page
 *  - icon : icon to display in navbar
 *  - route : actual route
 * @returns
 */
export default function FormsPage({ route }) {
	const dispatch = useDispatch();
	const [addNewLabel, setAddNewLabel] = useState(false);
	const [addNewSkill, setAddNewSkill] = useState(false);
	const [editSkill, setEditSkill] = useState(false);
	const [editItem, setEditItem] = useState({});
	const [valuesForm, setValuesForm] = useState({});
	const [formBuild, setFormBuild] = useState({});
	const [contextDictionary, setContextDictionary] = useState({});
	const [PI, setPI] = useState(false);
	const [empty, setEmpty] = useState(false);
	const [confirmDelete, setConfirmDelete] = useState(false);

	const { profile, filters } = useSelector(state => state);
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;
	// Loader while charts are loading
	const [chartsLoading, setChartsLoading] = useState(false);
	// Raw charts from collection "analytics" in DB
	const [rawCharts, setRawCharts] = useState({});
	// Raw charts builded from the back
	const [chartsData, setChartsData] = useState({});
	// Default assistant filters
	const [pageFilters, setPageFilters] = useState([]);
	const [listAPIs, setlistAPIs] = useState([]);
	const [selectedItem, setSelectedItem] = useState({});
	const [selectedTarget, setSelectedTarget] = useState("");
	const [routeDict, setRouteDict] = useState("");
	const [flagAccount, setFlagAccount] = useState(false);
	const [regexEmail, setRegexEmail] = useState("");
	const [selectedId, setSelectedId] = useState(null);
	const [reloadTable, setReloadTable] = useState(false);

	const [listValuesFilter, setListValuesFilter] = useState([]);

	const [formSelectUpdate, setFormSelectUpdate] = useState(true);

	/**
	 * Get charts data to be displayed in front
	 */
	function getChartsData() {
		return chartsData[route.route] ?? [];
	}
	/**
	 * FIRST STEP : Get raw charts from DB
	 */
	function getRawChartsFromDatabase() {
		const onSuccess = res => {
			setPageFilters(res.filters);
			setRawCharts({ ...rawCharts, [route.route]: res.charts });
		};
		dispatch(ChartsActions.getCharts(profile.assistantID, route, onSuccess));

		if (route.route === "/profils") {
			dispatch(
				FormActions.getItemsFromCollection(
					"skill",
					{
						query: {}
					},
					res => {
						if (!_lod.isEmpty(res?.items)) {
							setListValuesFilter(res.items.map(item => ({ label: item.name, value: item.code })));
						} else {
							setListValuesFilter([]);
						}
					}
				)
			);
		}
	}
	/**
	 * SECOND STEP : Build charts with the raw charts on the back
	 */
	function buildRawCharts() {
		// Success handler : set charts data
		const onSuccess = res => {
			setChartsData({ ...chartsData, [route.route]: res.charts });
			setChartsLoading(false);
		};
		// Build charts
		if (!rawCharts[route.route]) return;
		let mandatoryFilters = pageFilters.map(filter => filter.attribute);
		dispatch(
			ChartsActions.buildRawCharts(rawCharts[route.route], filters, mandatoryFilters, onSuccess)
		);
	}
	/**
	 * FIRST STEP : Get raw charts :
	 * - On mount / when page change
	 * - On selected assistant change
	 */
	useEffect(() => {
		setChartsLoading(true);
		getRawChartsFromDatabase();
	}, [profile.selectedAssistant.assistantID, route]);
	/**
	 * SECOND STEP : Build charts :
	 * - On filters change
	 * - On rawCharts change
	 */
	useEffect(() => {
		buildRawCharts();
	}, [rawCharts]);

	const actionEditHandle = (items, target, account) => {
		const onSuccess = res => {
			setAddNewLabel(true);
			setEmpty(false);
			setPI(true);
			setValuesForm(res.valueDictionary);
			setlistAPIs(res.APIs);
			setFormBuild(res.formBuilderDictionary);
			setContextDictionary(res.dictionary);
			setSelectedId(items._id);
			setRegexEmail(res.regexEmail);
			setSelectedTarget(target);
			setRouteDict(route.form.routeDictionary);
			setFlagAccount(account);
		};
		dispatch(
			FormActions.getItemByID(
				items._id,
				target,
				route.form.routeDictionary,
				onSuccess,
				false,
				account
			)
		);
	};

	const actionEditEmptyHandle = () => {
		const onSuccess = res => {
			setAddNewLabel(true);
			setEmpty(true);
			setValuesForm(res.valueDictionary);
			setlistAPIs(res.APIs);
			setRegexEmail(res.regexEmail);
			setFormBuild(res.formBuilderDictionary);
			setContextDictionary(res.dictionary);
			setPI(true);
			setSelectedTarget(route.form.collectionDefault);
			setRouteDict(route.form.routeDictionary);
		};

		dispatch(
			FormActions.getItemEmpty(route.form.collectionDefault, route.form.routeDictionary, onSuccess)
		);
	};

	const actionAddSkillHandle = () => {
		setAddNewSkill(true);
	};

	const actionDeleteHandle = (item, target) => {
		setConfirmDelete(true);
		setSelectedItem(item);
		setSelectedTarget(target);
	};

	const closeDeleteHandle = () => {
		setConfirmDelete(false);
	};

	const deleteCustomer = () => {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("FORMS.deleteSuccess"),
					type: "success"
				})
			);
			setReloadTable(!reloadTable);
			setConfirmDelete(false);
		};
		if (selectedItem._id) {
			// if (selectedTarget === "skill") {
			// 	dispatch(
			// 		SkillActions.deleteSkill("profile", "skill", selectedItem, profile.assistantID, onSuccess)
			// 	);
			// } else {
			// 	dispatch(FormActions.deleteItem(selectedItem._id, selectedTarget, {}, onSuccess));
			// }
			dispatch(FormActions.deleteItem(selectedItem._id, selectedTarget, {}, onSuccess));
		} else {
			setConfirmDelete(false);
			dispatch(
				display({
					message: i18n.t("FORMS.deleteError"),
					type: "error"
				})
			);
		}
	};

	const actionSaveOrCreateCustomer = (values, unique, callback) => {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("FORMS.addEmpty"),
					type: "success"
				})
			);
			setReloadTable(!reloadTable);
			setAddNewLabel(false);
			callback(true);
		};
		let data = { values, target: selectedTarget, unique, isAccount: flagAccount };
		if (empty) {
			dispatch(
				FormActions.addItemEmpty(data, onSuccess, err => {
					callback(false);
				})
			);
		} else {
			dispatch(
				FormActions.updateItem(selectedId, data, onSuccess, err => {
					callback(false);
				})
			);
		}
	};

	const actionCreateSkill = (profile, skill) => {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("FORMS.addEmpty"),
					type: "success"
				})
			);
			setAddNewSkill(false);
			setReloadTable(!reloadTable);
		};
		dispatch(
			SkillActions.addSkill("profile", "skill", profile, skill, profile.assistantID, onSuccess)
		);
	};

	const actionEditSkill = (profile, skill, oldSkill, removedProfiles) => {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("FORMS.addEmpty"),
					type: "success"
				})
			);
			setAddNewSkill(false);
			setReloadTable(!reloadTable);
		};
		dispatch(
			SkillActions.editSkill(
				"profile",
				"skill",
				profile,
				skill,
				oldSkill,
				removedProfiles,
				profile.assistantID,
				onSuccess
			)
		);
	};

	/**
	 * Charts loader
	 */
	if (chartsLoading) {
		return (
			<DashboardLayout>
				<MDBox py={3}>
					<DashboardNavbar />
					<ChartsLoader darkMode={darkMode} />
				</MDBox>
			</DashboardLayout>
		);
	} else
	/**
	 * Main component
	 */
		return (
			<DashboardLayout>
				<MDBox mb={3}>
					<DashboardNavbar
						filters={[
							<MDBox display="flex" alignItems="center">
								{route?.form?.collectionDefault === "profile" ? (
									<MDButton
										style={{ height: "100%", marginRight: "0.75rem" }}
										variant="contained"
										color="info"
										onClick={() => window.open(process.env.REACT_APP_ACC + "/profils", "_blank")}
									>
										<Icon>add</Icon>&nbsp;
										{i18n.t("FORMS.LABELS.add")}
										{route?.form?.pageLabel ?? route.name}
									</MDButton>
								) : (
									<ButtonNewLabel
										handleClick={() => {
											actionEditEmptyHandle();
										}}
										namePage={route?.form?.pageLabel ?? route.name}
									/>
								)}
								{pageFilters && (
									<MDBox display="flex">
										{parseFilters(
											profile.assistantID,
											route.route,
											pageFilters,
											filters,
											dispatch,
											listValuesFilter
										)}
									</MDBox>
								)}
							</MDBox>
						]}
					/>
				</MDBox>

				{addNewSkill && (
					<AddSkillDialog
						open={addNewSkill}
						handleCloseDialog={() => setAddNewSkill(false)}
						handleSave={(profiles, skill) => actionCreateSkill(profiles, skill)}
					/>
				)}

				{editSkill && (
					<AddSkillDialog
						open={editSkill}
						handleCloseDialog={() => setEditSkill(false)}
						handleSave={(profiles, skill, oldSkill, removedProfiles) =>
							actionEditSkill(profiles, skill, oldSkill, removedProfiles)
						}
						itemToEdit={editItem}
					/>
				)}

				{addNewLabel && (!_lod.isEmpty(valuesForm) || empty) && (
					<FormDictionaryDialog
						open={addNewLabel}
						route={route}
						handleCloseDialog={() => setAddNewLabel(false)}
						handleSave={(values, unique, callback) =>
							actionSaveOrCreateCustomer(values, unique, callback)
						}
						listAPIs={listAPIs}
						regexEmail={regexEmail}
						valuesDictionary={valuesForm}
						formBuildDictionary={formBuild}
						contextDictionary={contextDictionary}
						PIaccess={PI}
						isEmpty={empty}
						target={selectedTarget}
						selectedId={selectedId}
						routeDict={routeDict}
						flagAccount={flagAccount}
					/>
				)}
				{confirmDelete && (
					<Dialog open={confirmDelete} onClose={closeDeleteHandle}>
						<DialogTitle>{i18n.t("FORMS.LABELS.delete")}</DialogTitle>
						<DialogContent>
							{i18n.t("FORMS.LABELS.confirmDelete")}: {selectedItem.name}
						</DialogContent>
						<DialogActions>
							<MDButton autoFocus onClick={closeDeleteHandle} variant="outlined" color="dark">
								{i18n.t("FORMS.cancel")}
							</MDButton>
							<MDButton onClick={deleteCustomer} color="info" variant="contained" autoFocus>
								{i18n.t("FORMS.validate")}
							</MDButton>
						</DialogActions>
					</Dialog>
				)}
				{/*
				 * Pagined table
				 */}
				{getChartsData()
					.filter(chart => chart.type === "paginedList")
					.map((chart, index) => {
						let chartFilters = getLocalStorageBackValues(
							profile.selectedAssistant.assistantID,
							route.route,
							filters
						);

						return (
							<DefaultDataTable
								key={index}
								formSelectUpdate={formSelectUpdate}
								form={route.form}
								dictionary={chart.dictionary}
								canSearch
								table={chart.data}
								display={chart.request.attributesDisplay}
								pagination={chart.pagination}
								list={chart}
								reloadTable={reloadTable}
								filters={chartFilters}
								actionEditHandle={items =>
									actionEditHandle(items, chart.request.collection, chart.request.account)
								}
								actionDeleteHandle={items => actionDeleteHandle(items, chart.request.collection)}
							/>
						);
					})}
			</DashboardLayout>
		);
}

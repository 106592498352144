/* eslint-disable guard-for-in */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unreachable */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-restricted-syntax */
import "./style.css";
import lod_ from "lodash";
import { Checkbox, CircularProgress, Divider, Icon, IconButton, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import FormAction from "redux-react/actions/formAction";
import { socket } from "redux-react/middleware/ws";
import PromptStudioActions from "redux-react/actions/promptStudioActions";
import { useEffect, useState } from "react";
import i18n from "i18n";

import MDBox from "components/Basics/MDBox";
import MDButton from "components/Basics/MDButton";

import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import ProcessChoice from "./components/ProcessChoice";
import RunningPage from "./components/RunningPage";
import { COLLECTIONS, PG_PROCESS_STATES, PG_STEP_DISPLAY } from "../constants";

/**
 * Main component for playground
 * @param {*} param0
 * @returns
 */
export default function PromptStudioPlaygroundPage({ route }) {
	const dispatch = useDispatch();

	// Step display
	const [isRunning, setIsRunning] = useState(false);
	const [stepDisplay, setStepDisplay] = useState(PG_STEP_DISPLAY.PROCESS);

	const [clearProcess, setClearProcess] = useState(false);

	// Constants loaded from DB
	const [promptTemplates, setPromptTemplates] = useState([]);
	const [llmProcess, setLlmProcess] = useState([]);
	const [llmModel, setLlmModel] = useState([]);

	const [variablesToDataset, setVariablesToDataset] = useState([]);

	// Playground object
	const [selectedDataset, setSelectedDataset] = useState({});
	const [selectedProcess, setSelectedProcess] = useState([]);
	const [selectedOptions, setSelectedOptions] = useState({});

	const [stateProcess, setStateProcess] = useState({});

	const selectProcess = processCode => {
		if (selectedProcess.includes(processCode)) {
			setSelectedProcess(selectedProcess.filter(code => code !== processCode));
			setStateProcess(lod_.omit(stateProcess, processCode));
		} else {
			setSelectedProcess([...selectedProcess, processCode]);
			setStateProcess({ ...stateProcess, [processCode]: { state: PG_PROCESS_STATES.WAITING } });
		}
	};

	const isDisplay = code => {
		return Boolean(stepDisplay === code);
	};

	/* Get all LLM models / promptTemplates */
	const getConstVariables = () => {
		// Load templates
		if (!promptTemplates.length) {
			dispatch(
				FormAction.getItemsFromCollection(COLLECTIONS.promptTemplate, {}, res => {
					if (res.items) {
						setPromptTemplates(res.items);
					}
				})
			);
		}
		// Get all LLM models
		dispatch(
			FormAction.getItemsFromCollection(
				COLLECTIONS.llmProcess,
				{
					sort: { name: 1 }
				},
				res => {
					if (res.items) {
						setLlmProcess(res.items);
					}
				}
			)
		);

		dispatch(
			FormAction.getItemsFromCollection(
				COLLECTIONS.llmModel,
				{ query: { documentType: "llmModel" }, sort: { name: 1 } },
				res => {
					if (res.items) {
						setLlmModel(res.items);
					}
				}
			)
		);
	};

	const startPlayground = () => {
		// 1- Convert dataset to good format, we need to send string to the variable
		let parsedDataset = {};

		for (let variable of variablesToDataset) {
			let value = lod_.get(selectedDataset, variable);
			// stringify only if it's not a string
			let stringifiedValue = typeof value === "object" ? JSON.stringify(value) : value;
			lod_.set(parsedDataset, variable, stringifiedValue);
		}

		// 2- Start the process
		setIsRunning(true);
		setClearProcess(!clearProcess);

		// Set all process to running
		setStateProcess(
			lod_.mapValues(stateProcess, (value, key) => {
				return {
					state: PG_PROCESS_STATES.RUNNING
				};
			})
		);

		const onSuccess = res => {
			setIsRunning(false);
		};

		// Start the process
		dispatch(
			PromptStudioActions.startPlayground(
				{
					inputs: parsedDataset,
					process: selectedProcess,
					options: selectedOptions
				},
				onSuccess
			)
		);
	};

	useEffect(() => {
		getConstVariables();
	}, []);

	const updateStateWS = ({ code, data }) => {
		setStateProcess(prev => {
			return {
				...prev,
				[code]: {
					state: data.success ? PG_PROCESS_STATES.DONE : PG_PROCESS_STATES.ERROR,
					...data
				}
			};
		});
	};

	/**
	 * When user add a new process, we need to check if there is any new dataset
	 * and if there is no dataset, we add it to the selectedDataset (add variable of prompts)
	 * to the dataset
	 */
	useEffect(() => {
		const regex = /\{\{\{(.*?)\}\}\}/g;
		let fullProcess = llmProcess.filter(process => selectedProcess.includes(process.code));

		let fullText = "";

		for (let process of fullProcess) {
			let stepProcess = process.steps[0];
			let messages = stepProcess.messages;

			for (let message of messages) {
				let mappedPreview = message.promptParts
					.map(part => {
						let prompt = promptTemplates.find(prompt => prompt.code === part);
						if (prompt) {
							return prompt.prompt;
						}
						return "";
					})
					.join("");

				fullText += mappedPreview;
			}
		}

		let matches = fullText.match(regex);

		if (!matches) {
			// No variable so we reset the dataset
			setSelectedDataset({});
			return;
		}

		matches = matches.map(match => {
			return match.replace("{{{", "").replace("}}}", "").trim();
		});

		matches = lod_.uniq(matches);

		setVariablesToDataset(matches);

		for (let match of matches) {
			if (!selectedDataset[match]) {
				setSelectedDataset(prev => {
					let prevClone = lod_.cloneDeep(prev);
					lod_.set(prevClone, match, "");
					return prevClone;
				});
			}
		}

		// Remove unused keys of dataset
		let dataKeys = Object.keys(selectedDataset);
		let oldKeys = dataKeys.filter(key => !matches.includes(key));

		for (let key of oldKeys) {
			setSelectedDataset(prev => {
				return lod_.omit(prev, key);
			});
		}
	}, [selectedProcess]);

	useEffect(() => {
		// WS
		socket?.on("promptStudioPlaygroundUpdateState", updateStateWS);

		return () => {
			socket.off("promptStudioPlaygroundUpdateState", updateStateWS);
		};
	}, []);

	const onChangeDataset = e => {
		setSelectedDataset(e.updated_src);
	};

	const getTabIconColor = code => {
		return isDisplay(code) ? "info" : "";
	};

	const getContent = () => {
		switch (stepDisplay) {
			/* Process component */
			case PG_STEP_DISPLAY.PROCESS:
				return (
					<ProcessChoice
						selectedDataset={selectedDataset}
						onChangeDataset={onChangeDataset}
						llmProcess={llmProcess}
						selectedProcess={selectedProcess}
						selectProcess={selectProcess}
						disabled={isRunning}
					/>
				);
			case PG_STEP_DISPLAY.RESULT:
				return (
					<RunningPage
						process={llmProcess.filter(process => selectedProcess.includes(process.code))}
						options={selectedOptions}
						state={stateProcess}
						llmModel={llmModel}
					/>
				);
			default:
				return <MDBox></MDBox>;
		}
	};

	return (
		<DashboardLayout>
			<MDBox>
				<DashboardNavbar
					filters={[
						<MDBox display="flex">
							<MDButton
								variant="contained"
								color="success"
								onClick={startPlayground}
								disabled={isRunning}
							>
								{isRunning ? (
									<CircularProgress color="white" size={15} thickness={5} sx={{ mr: 1 }} />
								) : (
									<Icon>play_arrow</Icon>
								)}
								&nbsp;Start
							</MDButton>
						</MDBox>
					]}
				/>
			</MDBox>
			<MDBox
				mt={2}
				display="flex"
				flexDirection="row"
				borderRadius="lg"
				bgColor="white"
				sx={{
					height: "85vh"
				}}
				style={{
					overflow: "hidden"
				}}
			>
				{/* Left pannel */}
				<MDBox
					// flex="2"
					display="flex"
					flexDirection="column"
					justifyContent="space-between"
					alignItems="center"
					sx={{
						boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)"
						// minWidth: "25%"
					}}
				>
					{/* Top bar */}
					<MDBox display="flex" flexDirection="column" alignItems="center" m={1}>
						{/* Process */}
						<Tooltip placement="left" title={i18n.t("PROMPT.PLAYGROUND.configureTitle")}>
							<IconButton
								size="large"
								color={getTabIconColor(PG_STEP_DISPLAY.PROCESS)}
								onClick={() => setStepDisplay(PG_STEP_DISPLAY.PROCESS)}
							>
								<Icon>settings</Icon>
							</IconButton>
						</Tooltip>
						<Divider sx={{ width: "100%", m: 0.5 }} />
						{/* Results */}
						<Tooltip placement="left" title={i18n.t("PROMPT.PLAYGROUND.viewTitle")}>
							<IconButton
								size="large"
								color={getTabIconColor(PG_STEP_DISPLAY.RESULT)}
								onClick={() => setStepDisplay(PG_STEP_DISPLAY.RESULT)}
							>
								<Icon>preview</Icon>
							</IconButton>
						</Tooltip>
					</MDBox>
					{/* Bottom bar */}
					<MDBox></MDBox>

					{/* Dataset */}
					{/* <MDBox
						bgColor="white"
						display="flex"
						sx={{
							boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
							zIndex: 15
						}}
						onClick={() => setStepDisplay("dataset")}
						className="clickablePannelItem"
					>
						<MDBox
							bgColor={isDisplay("dataset") ? "info" : "white"}
							style={{
								width: "10px"
							}}
						></MDBox>
						<MDBox p={2} flex="1">
							<MDTypography variant="h5">{`Données d'entrée`}</MDTypography>
						</MDBox>
					</MDBox> */}
					{/*
					 * DATASET
					 */}
					{/* {isDisplay("dataset") && (
						<MDBox flex="1" m={1}>
							<ReactJson
								src={selectedDataset}
								name={null}
								style={{
									fontSize: "medium"
								}}
								enableClipboard={false}
								displayDataTypes={false}
								onEdit={onChangeDataset}
								onAdd={onChangeDataset}
								onDelete={onChangeDataset}
							/>
						</MDBox>
					)} */}

					{/* Process */}
					{/* <MDBox
						bgColor="white"
						display="flex"
						sx={{
							boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
							zIndex: 15
						}}
						onClick={() => setStepDisplay("process")}
						className="clickablePannelItem"
					>
						<MDBox
							bgColor={isDisplay("process") ? "info" : "white"}
							style={{
								width: "10px"
							}}
						></MDBox>
						<MDBox p={2} flex="1">
							<MDTypography variant="h5">Choix des processus</MDTypography>
							<MDBadge
								color={selectedProcess.length > 0 ? "success" : "warning"}
								circular
								variant="contained"
								size="xs"
								badgeContent={
									selectedProcess.length > 0 ? `${selectedProcess.length} process` : "Aucun"
								}
								container
							></MDBadge>
						</MDBox>
					</MDBox> */}
					{/*
					 * PROCESS
					 */}
					{/* {isDisplay("process") && (
						<MDBox flex="1" p={1} style={{ overflowY: "auto" }}>
							<ProcessChoice
								llmProcess={llmProcess}
								selectedProcess={selectedProcess}
								selectProcess={selectProcess}
								disabled={isRunning}
							/>
						</MDBox>
					)} */}
				</MDBox>
				{/* Middle pannel */}
				<MDBox
					flex="1"
					// style={{
					// 	width: "75%"
					// }}
				>
					{getContent()}
				</MDBox>
			</MDBox>
		</DashboardLayout>
	);
}

/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/**
 * #######################################################@
 *
 * Filter settings
 *
 * #######################################################@
 */
import "./style.css";

import { Dialog, DialogActions, DialogContent, DialogTitle, Icon, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import MDTypography from "components/Basics/MDTypography";
import InputBoolean from "components/Custom/LittleForm/Inputs/InputBoolean";
import i18n from "i18n";
import lod_ from "lodash";
import DictionaryMenu from "./DictionaryMenu";

export default function EditFilterDialog({
	open,
	handleCloseDialog,
	handleSave,
	dictionary,
	filter
}) {
	const [name, setName] = useState(null);
	const [filters, setFilters] = useState([]);

	const [anchorEl, setAnchorEl] = useState(null);

	const isDisabled = !name || filters.length < 1;
	/**
	 * Close dialog
	 */
	function close() {
		if (anchorEl) {
			setAnchorEl(null);
		} else {
			handleCloseDialog();
			setName(null);
			setFilters([]);
		}
	}
	/**
	 * Save filter
	 */
	function sumbit() {
		handleSave({ name, filters, code: filter.code, active: true });
		close();
	}
	/**
	 * Remove filter from filters list
	 */
	function removeItem(item) {
		setFilters(filters.filter(f => f.path !== item.path));
	}
	/**
	 * Add filter to filters list
	 */
	const addItem = item => {
		let sourcePath = item.split(".")[0];
		let sourceObject = lod_.get(dictionary, sourcePath)?.source;
		let itemLabel = lod_.get(dictionary, item.replaceAll(".", ".items."))?.label?.fr;
		let dictionaryPath = lod_.clone(item);

		switch (sourceObject) {
			case "custom":
			case "dataSources":
				// If item is from dataSources, we need to use root path (si remove first part of path)
				item = item.split(".").slice(1).join(".");
				break;
			case "assistantConfig":
				// If item is from assistantConfig, we need to use dictionary path
				item = "dictionary." + item;
				break;
			default:
				break;
		}

		setAnchorEl(null);
		let same = filters.find(f => f.dictionaryPath === dictionaryPath);
		if (!same) {
			let filter = {
				path: item,
				dictionaryPath,
				source: sourceObject,
				label: itemLabel
			};
			setFilters([...filters, filter]);
		}
	};
	/**
	 * Display name of dictionary element
	 */
	const getLabel = item => {
		let itemDic = lod_.get(dictionary, item.dictionaryPath.replaceAll(".", ".items."));
		return itemDic.label?.fr;
	};

	const checkFilterValidity = filter => {
		if (!filter.path || !filter.label || !filter.dictionaryPath) return false;
		let itemDic = lod_.get(dictionary, filter.dictionaryPath.replaceAll(".", ".items."));
		if (!itemDic) return false;
		return true;
	};

	useEffect(() => {
		setName(filter.name ?? "");
		setFilters(filter.filters ?? []);
	}, [filter]);
	/**
	 * Main component
	 */
	return (
		<Dialog fullWidth maxWidth="md" open={open} onClose={close}>
			<DialogTitle>{i18n.t("SETTINGS.FILTERS.edit")}</DialogTitle>
			<DialogContent>
				<MDBox mt={1}>
					<MDInput
						value={name || ""}
						className="dialogInput"
						label={i18n.t("SETTINGS.FILTERS.name")}
						onChange={e => setName(e.target.value)}
					/>
				</MDBox>

				<MDBox mt={2} display="flex" alignItems="center" justifyContent="space-between">
					<MDTypography variant="h6">{i18n.t("SETTINGS.FILTERS.items")}</MDTypography>

					<MDButton variant="gradient" color="info" onClick={e => setAnchorEl(e.target)}>
						{i18n.t("SETTINGS.add")}
					</MDButton>
					<DictionaryMenu
						dictionary={dictionary}
						anchorEl={anchorEl}
						handleInsertText={addItem}
						handleClose={() => setAnchorEl(null)}
					/>
				</MDBox>

				{!filters.length && (
					<MDBox mt={1} display="flex" justifyContent="center">
						<MDTypography variant="overline">{i18n.t("SETTINGS.FILTERS.noItems")}</MDTypography>
					</MDBox>
				)}

				{filters.length > 0 && (
					<>
						{filters.map((filter, index) => {
							let dictionaryItem = lod_.get(
								dictionary,
								filter.dictionaryPath.replaceAll(".", ".items.")
							);
							let valid = checkFilterValidity(filter);
							if (!valid) {
								return (
									<MDBox
										key={index}
										mt={1.5}
										bgColor="error"
										borderRadius="lg"
										shadow="lg"
										opacity={1}
										p={1}
										display="flex"
										alignItems="center"
										justifyContent="space-between"
									>
										<MDBox>
											<MDTypography variant="h6">{`⚠️ Broken filter (${
												filter.label ?? filter.path
											})`}</MDTypography>
										</MDBox>

										<IconButton
											onClick={() => {
												removeItem(filter);
											}}
										>
											<Icon fontSize="medium">close</Icon>
										</IconButton>
									</MDBox>
								);
							}
							return (
								<MDBox
									key={index}
									mt={1.5}
									bgColor="light"
									borderRadius="lg"
									shadow="lg"
									opacity={1}
									p={1}
									display="flex"
									alignItems="center"
									justifyContent="space-between"
								>
									<MDBox>
										<MDTypography variant="overline">
											{filter.path.replaceAll(".", "/")}
										</MDTypography>
										<MDTypography variant="h6">{getLabel(filter)}</MDTypography>

										{dictionaryItem?.type === "boolean" && (
											<MDBox display="flex" alignItems="center" justifyContent="space-between">
												<InputBoolean
													label="Inverser"
													value={filter.inverted}
													onChange={val => {
														let newFilters = filters.map(f => {
															if (f.dictionaryPath === filter.dictionaryPath) {
																f.inverted = val;
															}
															return f;
														});
														setFilters(newFilters);
													}}
													InputProps={{
														bgColor: "white"
													}}
												/>
												<InputBoolean
													label="Oui - optionnel"
													value={(filter.optionnal ?? []).includes("yes")}
													onChange={val => {
														let optionnal = filter.optionnal ?? [];
														if (val) {
															optionnal.push("yes");
														} else {
															optionnal = optionnal.filter(o => o !== "yes");
														}

														let newFilters = filters.map(f => {
															if (f.dictionaryPath === filter.dictionaryPath) {
																f.optionnal = optionnal;
															}
															return f;
														});

														setFilters(newFilters);
													}}
													InputProps={{
														sx: { ml: 1 },
														bgColor: "white"
													}}
												/>
												<InputBoolean
													label="Non - optionnel"
													value={(filter.optionnal ?? []).includes("no")}
													onChange={val => {
														let optionnal = filter.optionnal ?? [];
														if (val) {
															optionnal.push("no");
														} else {
															optionnal = optionnal.filter(o => o !== "no");
														}

														let newFilters = filters.map(f => {
															if (f.dictionaryPath === filter.dictionaryPath) {
																f.optionnal = optionnal;
															}
															return f;
														});

														setFilters(newFilters);
													}}
													InputProps={{
														sx: { ml: 1 },
														bgColor: "white"
													}}
												/>
											</MDBox>
										)}
									</MDBox>

									<IconButton
										onClick={() => {
											removeItem(filter);
										}}
									>
										<Icon fontSize="medium">close</Icon>
									</IconButton>
								</MDBox>
							);
						})}
					</>
				)}
			</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="dark" onClick={close}>
					{i18n.t("SETTINGS.cancel")}
				</MDButton>
				<MDButton disabled={isDisabled} variant="contained" color="dark" onClick={sumbit}>
					{i18n.t("SETTINGS.edit")}
				</MDButton>
			</DialogActions>
		</Dialog>
	);
}

import { Fade, Grow } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import skillsActions from "redux-react/actions/skillActions";
import MDBox from "components/Basics/MDBox";
import InformationCardUser from "../components/InformationCardUser";

/**
 * Step 1: Selected the profile type
 */
const SkillEditorStepChooseProfil = ({
	profile,
	handleSelectUser,
	validStep,
	skillToCheckExistenceInprofile = {}
}) => {
	const dispatch = useDispatch();
	const [profileList, setProfileList] = useState([]);
	const [selectedProfileList, setSelectedProfileList] = useState([]);
	// Handle select profile
	const selectProfile = user => {
		handleSelectUser(user);
		// when i click on a profile, if it is already in the list of selected users, remove it from the list
		let index = selectedProfileList.findIndex(profile => profile.userID === user.userID);
		if (index !== -1) {
			let copy = [...selectedProfileList];
			copy.splice(index, 1);
			setSelectedProfileList(copy);
			return;
		}
		setSelectedProfileList([...selectedProfileList, user]);
	};

	useEffect(() => {
		// We should loop through the profile item sent by AddSkillDialog, if we find at least 1 object we send valid step
		// we must populate the selectedProfileList with the profile item sent by AddSkillDialog too
		// we should do that only if we don't have to check a skill
		if (profile.length > 0 && !skillToCheckExistenceInprofile.code) {
			validStep(true);
			setSelectedProfileList(profile);
		}
		const onSuccess = res => {
			setProfileList(res.profils);
			// check if the retrieved profils possess a skill array and if the skillToCheckExistenceInprofile is in the array
			// if we already have profiles sent to this step, it means we are coming back to this specific step
			// so we don't need to do the whole process again
			if (profile.length === 0) {
				if (skillToCheckExistenceInprofile.code) {
					res.profils.forEach(profile => {
						// now that we have a profile, we need to check its skill array, and if we find a match
						// between the skill.name in the array and the skillToCheckExistenceInprofile.name,
						// we add it to the selectedProfileList. We do it only if the profile has a skill array
						if (profile.skill) {
							Object.keys(profile.skill).forEach(skillIndex => {
								// it bugs out here probably, because the first profile we loop through doesn't have any skill array
								if (profile.skill[skillIndex].code === skillToCheckExistenceInprofile.code) {
									// here we work with the previous state of the selectedProfileList, making it possible to update twice in a row
									setSelectedProfileList(prevSelectedProfileList => [
										...prevSelectedProfileList,
										profile
									]);
									// we also need to send the profile to the parent component
									handleSelectUser(profile);
								}
							});
						}
					});
				}
			} else {
				setSelectedProfileList(profile);
			}
		};
		// only dispatch if we have no profile sent to this step
		dispatch(skillsActions.getProfils("profile", onSuccess));
	}, []);

	useEffect(() => {
		if (selectedProfileList.length === 0) {
			validStep(false);
		} else {
			validStep(true);
		}
	}, [selectedProfileList]);

	return (
		<MDBox>
			<MDBox mt={3} display="flex" flexDirection="column" justifyContent="space-evenly">
				{profileList?.length > 0 && (
					<>
						{profileList.map((c, index) => {
							return (
								<Grow key={index} in timeout={(index + 1) * 500}>
									<MDBox display="flex">
										<MDBox flex="1">
											<InformationCardUser
												element={c}
												onClick={() => selectProfile(c)}
												selected={selectedProfileList.some(profile => profile.userID === c.userID)}
											/>
										</MDBox>
									</MDBox>
								</Grow>
							);
						})}
					</>
				)}
			</MDBox>
		</MDBox>
	);
};

export default SkillEditorStepChooseProfil;

/* eslint-disable import/prefer-default-export */
import lod_ from "lodash";
/**
 * Create a datas skeleton from a dictionary
 * @param {*} dictionary
 * @param {*} values
 * @returns
 */
export function createDictionarySkeleton(dictionary, values = {}, path = null) {
	if (dictionary.items) {
		dictionary = dictionary.items;
	}

	let metadatas = {};
	let keys = Object.keys(dictionary);

	// Special handle for empty object in dictionary but we have a value in values
	if (keys.length === 0) {
		let possibleValue = lod_.get(values, path);
		if (possibleValue) {
			return possibleValue;
		}
	}

	keys.map(key => {
		let item = dictionary[key];
		let tempPath = `${path ? `${path}.` : ""}${key}`;

		if (item.type === "level") {
			// If it's a level, we need to check childrens
			lod_.set(metadatas, key, createDictionarySkeleton(item.items, values, tempPath));
		} else {
			// Get possible value with full path
			let possibleValue = lod_.get(values, tempPath);
			// Set value
			let defaultValue = possibleValue ?? item.defaultValue ?? undefined;

			switch (item.type) {
				case "boolean":
					defaultValue = defaultValue ?? false;
					break;
				case "number":
					defaultValue = defaultValue ?? (item?.isArray ? [] : 0);
					break;
				case "delay":
					defaultValue = defaultValue ?? 0;
					break;
				case "date":
					if (defaultValue === "now") {
						defaultValue = new Date();
					}
					break;
				default:
					defaultValue = defaultValue ?? (item?.isArray ? [] : "");
					break;
			}

			// Set value on object at actual key
			lod_.set(metadatas, key, defaultValue);
		}
	});

	return metadatas;
}

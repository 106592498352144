import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import MDTypography from "components/Basics/MDTypography";
import i18n from "i18n";
import { useEffect, useState } from "react";

const InputDelay = ({ label, value, onChange }) => {
	const [days, setDays] = useState(Math.floor(value / (24 * 3600 * 1000)));
	const [hours, setHours] = useState(Math.floor((value % (24 * 3600 * 1000)) / (3600 * 1000)));
	const [minutes, setMinutes] = useState(Math.floor((value % (3600 * 1000)) / (60 * 1000)));
	const [seconds, setSeconds] = useState(Math.floor((value % (60 * 1000)) / 1000));

	useEffect(() => {
		let newValue =
			days * 24 * 3600 * 1000 + hours * 3600 * 1000 + minutes * 60 * 1000 + seconds * 1000;
		onChange(newValue);
	}, [days, hours, minutes, seconds]);

	return (
		<MDBox>
			<MDBox mr={1}>
				<MDTypography variant="body1" fontSize="small">
					{label}
				</MDTypography>
			</MDBox>
			<MDBox mt={1} display="flex" alignItems="center">
				<MDBox mr={1} flex={1}>
					<MDInput
						type="number"
						className="dialogInput"
						label={i18n.t("SETTINGS.CHARTS.DATE.day")}
						value={days}
						onChange={e => setDays(e.target.value)}
						fullWidth
					/>
				</MDBox>
				<MDBox mr={1} flex={1}>
					<MDInput
						type="number"
						className="dialogInput"
						label={i18n.t("SETTINGS.CHARTS.DATE.hour")}
						value={hours}
						onChange={e => setHours(e.target.value)}
						inputProps={{
							max: 23,
							min: 0
						}}
					/>
				</MDBox>
				<MDBox mr={1} flex={1}>
					<MDInput
						type="number"
						className="dialogInput"
						label={i18n.t("SETTINGS.CHARTS.DATE.minute")}
						value={minutes}
						onChange={e => setMinutes(e.target.value)}
						inputProps={{
							max: 59,
							min: 0
						}}
					/>
				</MDBox>
				<MDBox flex={1}>
					<MDInput
						type="number"
						className="dialogInput"
						label={i18n.t("SETTINGS.CHARTS.DATE.second")}
						value={seconds}
						onChange={e => setSeconds(e.target.value)}
						inputProps={{
							max: 59,
							min: 0
						}}
					/>
				</MDBox>
			</MDBox>
		</MDBox>
	);
};
export default InputDelay;

/* eslint-disable react/no-unescaped-entities */
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import lod_ from "lodash";
import { useEffect, useState } from "react";
import OneValueChart from "pages/charts/oneValueChart";
import ProportionChart from "pages/charts/proportionChart";
import CrossTable from "pages/charts/crossTable";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";
import ChartsActions from "redux-react/actions/chartsActions";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import MDInput from "components/Basics/MDInput";
import {
	Box,
	Chip,
	FormControl,
	Icon,
	IconButton,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	Switch
} from "@mui/material";
import i18n from "i18n";
import notFoundImage from "assets/images/error.svg";
import { selectFilteredSortedMenu } from "redux-react/reducers/profileReducer";
import C from "../constants";
import IconAccordion from "../components/IconAccordion";

const DisplayChoiceStep = ({ chart, setChart, validStep }) => {
	const [error, setError] = useState(false);
	const dispatch = useDispatch();
	const pages = useSelector(selectFilteredSortedMenu);

	const [previewChart, setPreviewChart] = useState(false);
	const [selectedPages, setSelectedPages] = useState([]);

	const [switchLabelController, setSwitchLabelController] = useState(
		Boolean(chart.display.datalabels)
	);
	// get previous request state
	const [previousRequest, setPreviousRequest] = useState(chart.request);

	const handleChange = (path, value) => {
		let copyChart = lod_.cloneDeep(chart);
		copyChart = lod_.set(copyChart, path, value);

		if (copyChart.display.title && copyChart.display.pages.length > 0 && !error) {
			validStep();
		} else {
			validStep(false);
		}

		setChart(copyChart);
	};

	const handleSwitchLabel = () => {
		setSwitchLabelController(!switchLabelController);
		handleChange("display.datalabels", !switchLabelController);
	};

	const handleSwitchLegend = () => {
		handleChange("options.legend", !chart.options.legend);
	};

	function getCorrectGraph(type) {
		try {
			switch (type) {
				case "oneValue":
					return <OneValueChart chart={chart} data={previewChart.data} />;
				case "proportion":
				case "oneDimension":
					return <ProportionChart chart={chart} data={previewChart.data} />;
				case "crossTable":
				case "timeSeries":
				case "multiCollection":
					return <CrossTable chart={chart} data={previewChart.data} />;
				case "paginedList":
					return (
						<DefaultDataTable
							list={chart}
							pagination={previewChart.pagination}
							canSearch
							table={previewChart.data}
							display={chart.request.attributesDisplay}
						/>
					);
				default:
					return null;
			}
		} catch (e) {
			return null;
		}
	}
	// Change preview chart
	const handleChangeSelect = event => {
		let { value } = event.target;
		setSelectedPages(typeof value === "string" ? value.split(",") : value);
	};
	// Load chart
	function loadChart() {
		const onSuccess = res => {
			if (res.charts.length === 0) {
				setError(true);
			} else {
				setPreviewChart(res.charts[0]);
			}
		};
		dispatch(ChartsActions.buildRawCharts([chart], [], [], onSuccess));
	}
	// Updates chart when request change
	useEffect(() => {
		if (!lod_.isEqual(previousRequest, chart.request)) {
			setPreviousRequest(chart.request);
			loadChart();
		}
	}, [chart.request]);
	// Updates selected pages
	useEffect(() => {
		handleChange(
			"display.pages",
			selectedPages.map(page => page.replace("/", ""))
		);
	}, [selectedPages]);
	// Default use effect
	useEffect(() => {
		handleChange("display", {
			...chart.display,
			datalabels: false,
			label: "",
			title: "Titre du graphique",
			description: "Description du graphique",
			icon: {
				component: "equalizer",
				color: "info"
			},
			pages: []
		});
		loadChart();
	}, []);

	return (
		<MDBox display="flex" className="fullHeight">
			{/* Left tab */}
			<MDBox flex="3" className="displayLeftTab">
				<MDTypography variant="h3">{i18n.t("SETTINGS.CHARTS.NEW.chartPreview")}</MDTypography>

				<MDBox className="dialogContentChartEditor fullHeight" p={3} borderRadius="md">
					<MDBox mt={4}>{previewChart && getCorrectGraph(chart.type)}</MDBox>
					{error && (
						<MDBox
							mt={2}
							display="flex"
							justifyContent="center"
							flexDirection="column"
							alignItems="center"
						>
							<MDBox mt={-1} component="img" src={notFoundImage} alt="not found" width="60%"></MDBox>
							<MDTypography variant="caption" color="error" mt={-1} fontSize="medium">
								{i18n.t("SETTINGS.CHARTS.NEW.cannotPreview")}
							</MDTypography>
						</MDBox>
					)}
				</MDBox>
			</MDBox>
			{/* Right Tab */}
			<MDBox flex="2" ml={1} className="displayRightTab">
				<MDTypography variant="h3">{i18n.t("SETTINGS.CHARTS.NEW.display")}</MDTypography>

				<MDBox mt={2}>
					{/* Titre */}
					<MDBox mt={1}>
						<MDInput
							className="dialogInput"
							label={
								<span>
									{i18n.t("SETTINGS.CHARTS.NEW.chartTitle")}
									<span className="mandatoryField">*</span>
								</span>
							}
							value={chart.display.title ?? ""}
							onChange={e => handleChange("display.title", e.target.value)}
						/>
					</MDBox>
					{/* Description */}
					{chart.type !== "oneValue" && (
						<MDBox mt={2}>
							<MDInput
								className="dialogInput"
								label={i18n.t("SETTINGS.CHARTS.NEW.chartDescription")}
								value={chart.display.description}
								onChange={e => handleChange("display.description", e.target.value)}
							/>
						</MDBox>
					)}
					{/* Icon */}
					<MDBox mt={1}>
						<MDTypography variant="overline">{i18n.t("SETTINGS.CHARTS.NEW.icon")}</MDTypography>
					</MDBox>
					<MDBox display="flex" alignItems="flex-start " flexDirection="column">
						<MDBox mt={1}>
							{/* Color choice */}
							<IconAccordion
								title={i18n.t("SETTINGS.CHARTS.NEW.iconAvailableColors")}
								content={
									<MDBox display="flex" flexWrap="wrap">
										{C.ICONS_COLORS.map((color, index) => {
											return (
												<MDBox
													key={index}
													mr={1}
													bgColor={color}
													variant="gradient"
													coloredShadow={color}
													borderRadius="xl"
													className="colorPickerContainer"
													onClick={() => {
														handleChange("display.icon.color", color);
													}}
												>
													<Icon fontSize="small">{chart.display.icon.component}</Icon>
												</MDBox>
											);
										})}
									</MDBox>
								}
							/>
							{/* Icon choice */}
							<IconAccordion
								title={i18n.t("SETTINGS.CHARTS.NEW.iconAvailableIcons")}
								content={C.ICONS_LIST.map((icon, index) => {
									return (
										<IconButton
											key={index}
											onClick={() => {
												let newIcon = {
													component: icon,
													color: chart.display.icon?.color || "info"
												};
												handleChange("display.icon", newIcon);
											}}
										>
											<Icon fontSize="large">{icon}</Icon>
										</IconButton>
									);
								})}
							/>
						</MDBox>
					</MDBox>
					{/* Pages */}
					<MDBox mt={3}>
						<FormControl fullWidth>
							<InputLabel id="select-pages-label">
								<span>
									{i18n.t("SETTINGS.CHARTS.NEW.displayChartOnPages")}
									<span className="mandatoryField">*</span>
								</span>
							</InputLabel>
							<Select
								labelId="select-pages-label"
								id="select-pages"
								multiple
								value={selectedPages}
								onChange={handleChangeSelect}
								input={
									<OutlinedInput
										id="select-pages-chip"
										label={
											<span>
												{i18n.t("SETTINGS.CHARTS.NEW.displayChartOnPages")}
												<span className="mandatoryField">*</span>
											</span>
										}
									/>
								}
								renderValue={selected => (
									<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
										{selected.map((page, index) => {
											let goodPage = pages.find(p => p.route === page);
											return <Chip key={index} label={goodPage.name} />;
										})}
									</Box>
								)}
							>
								{pages.map((page, index) => (
									<MenuItem key={index} value={page.route}>
										{page.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</MDBox>
					{/* ################################################ */}
					{/*	PART FOR : ONEVALUE / ONEDIMENSION / PROPORTION  */}
					{/* ################################################ */}
					{["oneValue", "oneDimension", "proportion"].includes(chart.type) && (
						<>
							{/* Limit */}
							{chart.type !== "oneValue" && (
								<MDBox mt={2}>
									<MDInput
										type="number"
										className="dialogInput"
										label={i18n.t("SETTINGS.CHARTS.NEW.displayLimit")}
										InputProps={{ inputProps: { min: 1 } }}
										onChange={e => {
											if (e.target.value < 1) e.target.value = null;
											handleChange("request.limit", e.target.value);
										}}
									/>
								</MDBox>
							)}
							{/* Labels */}
							<MDBox mt={2}>
								<MDInput
									className="dialogInput"
									label={i18n.t("SETTINGS.CHARTS.NEW.displayLabel")}
									onChange={e => handleChange("display.label", e.target.value)}
								/>
							</MDBox>
							{/* Etiquettes */}
							{chart.type !== "oneValue" && (
								<MDBox
									mt={2}
									display="flex"
									justifyContent="space-between"
									alignItems="center"
									className="customSwitchContainer"
									onClick={() => handleSwitchLabel()}
								>
									<span>{i18n.t("SETTINGS.CHARTS.NEW.displayDatalabels")}</span>
									<MDBox>
										<Switch checked={switchLabelController} />
									</MDBox>
								</MDBox>
							)}
						</>
					)}
					{/* ################################################ */}
					{/*	PART FOR : TIMESERIES						     */}
					{/* ################################################ */}
					{["timeSeries"].includes(chart.type) && (
						<>
							{/* Legende */}
							<MDBox
								mt={2}
								display="flex"
								justifyContent="space-between"
								alignItems="center"
								className="customSwitchContainer"
								onClick={() => handleSwitchLegend()}
							>
								<span>Afficher la légende</span>
								<MDBox>
									<Switch checked={chart.options.legend} />
								</MDBox>
							</MDBox>
						</>
					)}
				</MDBox>
			</MDBox>
		</MDBox>
	);
};

export default DisplayChoiceStep;

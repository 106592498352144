import LlmModelList from "./LlmModel";

const { default: MDBox } = require("components/Basics/MDBox");

/**
 * Container for one message in left pannel
 * @param {*} param0
 * @returns
 */
const ModelMenuItem = ({ model, onClick, stepDisplay, dbModel, llmDocumentType, ...rest }) => {
	return (
		<MDBox
			onClick={onClick}
			display="flex"
			alignItems="stretch"
			{...rest}
			style={{
				borderBottom: "1px solid #f0f0f0"
			}}
		>
			<MDBox
				bgColor={stepDisplay === model.code ? "info" : "white"}
				style={{
					width: "10px"
				}}
			></MDBox>
			<MDBox p={1} flex="1">
				<LlmModelList
					model={model}
					dbModel={dbModel}
					llmDocumentType={llmDocumentType.find(doc => doc.code === model.documentType)}
				/>
			</MDBox>
		</MDBox>
	);
};

export default ModelMenuItem;

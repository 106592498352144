/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
import axios from "axios";
import store from "redux-react/store";

export async function getUploadS3SignedUrlAnswerItem(
	fileName,
	contentType,
	size,
	type,
	answerItemID,
	langue
) {
	const endPoint = process.env.REACT_APP_AMBACK;

	let user = store.getState().user;
	let assistantID = store.getState().profile.assistantID;

	let uploadSignedUrlResponse = await axios(endPoint + "/api/v1/s3/v3/uploadSignedUrl/answerItem", {
		headers: {
			Authorization: `Bearer ${user.token}`,
			UserEmail: user.email
		},
		method: "POST",
		data: {
			assistantID,
			fileName,
			contentType,
			size,
			type,
			answerItemID,
			langue
		}
	});

	return uploadSignedUrlResponse.data;
}

export async function getSignedUrlS3(file) {
	const endPoint = process.env.REACT_APP_AMBACK;

	let user = store.getState().user;
	let assistantID = store.getState().profile.assistantID;

	let uploadSignedUrlResponse = await axios(endPoint + "/api/v1/s3/v3/getSignedUrl", {
		headers: {
			Authorization: `Bearer ${user.token}`,
			UserEmail: user.email
		},
		method: "POST",
		data: {
			assistantID,
			size: file.size,
			fileName: file.name,
			contentType: file.type
		}
	});

	return uploadSignedUrlResponse.data.payload.result;
}

export async function uploadToSignedUrl(signedURL, file) {
	const response = await axios.put(signedURL, file, {
		headers: {
			"Content-Type": file.type
		}
	});

	return {};
}

// new way to delete file
export async function deleteFileFromPath(path) {
	const endPoint = process.env.REACT_APP_AMBACK;

	let user = store.getState().user;
	let assistantID = store.getState().profile.assistantID;

	await axios(endPoint + "/api/v1/s3/v3/deleteFile", {
		headers: {
			Authorization: `Bearer ${user.token}`,
			UserEmail: user.email
		},
		method: "POST",
		data: {
			assistantID,
			path
		}
	});
	return {};
}

// Do not use
export async function removeToSignedUrl(urlFile) {
	const endPoint = process.env.REACT_APP_AMBACK;

	let user = store.getState().user;
	let assistantID = store.getState().profile.assistantID;

	await axios(endPoint + "/api/v1/s3/v3/deleteFileUrl", {
		headers: {
			Authorization: `Bearer ${user.token}`,
			UserEmail: user.email
		},
		method: "POST",
		data: {
			assistantID,
			urlFile
		}
	});
	return {};
}

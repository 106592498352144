import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { t } from "i18next";

/**
 * Model component for the list of LLM models
 * @param {*} param0
 * @returns
 */
const LlmModelList = ({ model, dbModel }) => {
	return (
		<MDBox display="flex" flexDirection="column" alignItems="stretch">
			<MDBox display="flex" alignItems="center" justifyContent="space-between" flex="1">
				<MDBox display="flex" alignItems="center">
					<MDBox mr={1} display="flex" alignItems="center">
						<img src={dbModel?.image} alt="AzureOpenAI" width="20" />
					</MDBox>
					<MDTypography variant="h6">{model.name}</MDTypography>
				</MDBox>
			</MDBox>
			<MDTypography variant="body2">
				{model.description || t("PROMPT.PROCESS.EDIT.TEMPLATES.noDescription")}
			</MDTypography>
		</MDBox>
	);
};

export default LlmModelList;

/* eslint-disable import/no-mutable-exports */
/* eslint-disable import/prefer-default-export */
import receiveWebsocketMessage from "hooks/websockets";
import { wsEvent, wsConnect } from "redux-react/actions/ws";
import { addNotification } from "redux-react/reducers/notificationsReducer";
import { display } from "redux-react/reducers/snackBarReducer";
import io from "socket.io-client";

const URL_WS = process.env.REACT_APP_AMBACK;

export let socket;

let user;
let assistantID;
let profile;

function closeWebSocket() {
	if (socket) {
		socket.close();
		socket = null;
	}
}

export const wsMiddleware =
	({ dispatch, getState }) =>
	next =>
	action => {
		if (!action) {
			return;
		}
		/* Connect WS */
		if (wsConnect.match(action)) {
			profile = action.payload?.profile;
			assistantID = action.payload?.profile?.assistantID;
			user = action.payload?.user;

			if (socket) {
				closeWebSocket();
			}
		}

		if (user && assistantID && !socket) {
			/* Create socket connection */
			socket = io(URL_WS, {
				auth: {
					authorization: `Bearer ${user.token}`,
					assistantID,
					appName: process.env.REACT_APP_NAME,
					appVersion: process.env.REACT_APP_VERSION,
					role: profile.role
				},
				transports: ["websocket"],
				secure: true,
				rejectUnauthorized: true
			});
			/* Socket connection establish */
			socket.on("connect", () => {});
			/* Socket disconnected */
			socket.on("disconnect", () => {});
			/* When receive any event */
			socket.onAny((event, data) => {
				// Websocket message handler
				switch (event) {
					case "display_snackbar": {
						const { type = "info", message = "" } = data;
						dispatch(display({ type, message }));
						break;
					}
					case "add_notification": {
						const { icon, type = "info", message, ts } = data;
						dispatch(addNotification({ assistantID, icon, message, ts, type }));
						break;
					}
					default:
						receiveWebsocketMessage({ dispatch, getState }, socket, event, data);
						break;
				}
			});
			/* Socket error */
			socket.on("connect_error", err => {});
		}
		/* Send WS event */
		if (wsEvent.match(action)) {
			if (socket?.connected) {
				socket.emit(action.payload.event, action.payload.payload);
			}
		}

		next(action);
	};

import { setError } from "redux-react/reducers/errorReducer";
import { display } from "redux-react/reducers/snackBarReducer";
import { api } from "./api";

const actions = {
	getProfils: (collection, system = false, onSuccess) => {
		let data = {
			collection,
			system
		};
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/skill/profils`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(display(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	getProfilsBySkill: (skillCode, onSuccess) => {
		let data = {
			skillCode
		};
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/skill/profils/skillCode`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(display(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	checkSkillAlreadyExists: (collection, skill, assistantID, onSuccess) => {
		let data = {
			collection,
			skill,
			assistantID
		};
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/skill/skills`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(display(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	getAllSkills: onSuccess => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/skills/all`,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(display(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	addSkill: (profileCollection, skillCollection, profiles, skill, assistantID, onSuccess) => {
		let data = {
			profileCollection,
			skillCollection,
			profiles,
			skill,
			assistantID
		};
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/skill/addSkill`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(display(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	editSkill: (
		profileCollection,
		skillCollection,
		profiles,
		skill,
		oldSkill,
		removedProfiles,
		assistantID,
		onSuccess
	) => {
		let data = {
			profileCollection,
			skillCollection,
			profiles,
			skill,
			oldSkill,
			removedProfiles,
			assistantID
		};
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/skill/editSkill`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(display(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	deleteSkill: (profileCollection, skillCollection, skillToDelete, assistantID, onSuccess) => {
		let data = {
			profileCollection,
			skillCollection,
			skillToDelete,
			assistantID
		};
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/skill/deleteSkill`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(display(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	}
};

export default actions;

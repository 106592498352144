/* eslint-disable no-extra-boolean-cast */
import { Icon, IconButton, InputAdornment, Tooltip } from "@mui/material";
import MDInput from "components/Basics/MDInput";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { display } from "redux-react/reducers/snackBarReducer";
import { v4 } from "uuid";

const generateApiKey = () => {
	return v4();
};

/**
 * Special input for webhook
 * @returns
 */
const InputWebhook = ({ label, value, item, onChange }) => {
	const dispatch = useDispatch();
	const [apiKey, setApiKey] = useState(value);

	const baseUrl = item?.webhook?.baseUrl;
	const url = `${baseUrl}?apiKey=${apiKey}`;
	// Regenerate the API key
	const regenerate = () => {
		const newApiKey = generateApiKey();
		setApiKey(newApiKey);
		onChange(newApiKey);
	};

	useEffect(() => {
		if (!value) {
			regenerate();
		}
	}, []);

	return (
		<MDInput
			sx={{ mt: 1 }}
			fullWidth
			label={label}
			value={url}
			disabled
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<Tooltip placement="top" title={t("SETTINGS.regenerate")}>
							<IconButton color="info" style={{ pointerEvents: "visible" }} onClick={regenerate}>
								<Icon>autorenew</Icon>
							</IconButton>
						</Tooltip>
						<Tooltip placement="top" title={t("SETTINGS.DICTIONARY.copyToClipboard")}>
							<IconButton
								color="info"
								style={{ pointerEvents: "visible" }}
								onClick={() => {
									navigator.clipboard.writeText(url);
									dispatch(
										display({
											message: t("FORMS.copyToClipboard"),
											type: "info"
										})
									);
								}}
							>
								<Icon>copy</Icon>
							</IconButton>
						</Tooltip>
					</InputAdornment>
				)
			}}
		/>
	);
};
export default InputWebhook;

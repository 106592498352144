import { setPermissions } from "redux-react/reducers/permissionsReducer";
import { updateMenu, selectProfile } from "redux-react/reducers/profileReducer";
import { display } from "redux-react/reducers/snackBarReducer";
import { api } from "./api";
import { wsConnect } from "./ws";

const actions = {
	selectProfile: (payload, assistants, user) => {
		return dispatch => {
			dispatch(
				wsConnect({
					user,
					profile: payload.profile
				})
			);
			dispatch(selectProfile({ profile: payload.profile, assistants }));
			dispatch(setPermissions(payload.permissions));
		};
	},
	// For a roles list, add a new permission for a given product and assistant
	createCustomPermission: (assistantID, rolesList, product, permission, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/permissions/${assistantID}/${product}`,
					data: { roles: rolesList, permission },
					onSuccess,
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error?.message ?? "none"
							})
						);
					}
				})
			);
		};
	},
	// When user change permissions for roles for a page
	updateRolesByPagePermission: (assistantID, pagePermission, roles, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "PUT",
					url: `/api/v1/roles/${assistantID}/${pagePermission}`,
					data: {
						roles
					},
					onSuccess,
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error ?? "none"
							})
						);
					}
				})
			);
		};
	},
	// Get all roles who have this page permission
	getRolesByPagePermission: (assistantID, pagePermission, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/roles/${assistantID}/${pagePermission}`,
					onSuccess,
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error ?? "none"
							})
						);
					}
				})
			);
		};
	},
	// Get all permissions for a given role
	getCustomPermissions: (assistantID, role, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/permissions/custom/${assistantID}/${role}`,
					onSuccess,
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error ?? "none"
							})
						);
					}
				})
			);
		};
	},
	updateMenu: (assistantID, success) => {
		const onSuccess = res => {
			if (success) success(res.menu);
			return dispatch => {
				dispatch(updateMenu({ menu: res.menu }));
			};
		};
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/menu/${assistantID}`,
					onSuccess,
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error?.message ?? "none"
							})
						);
					}
				})
			);
		};
	}
};

export default actions;

import { useState } from "react";
import { t } from "i18next";

import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { FormControl, Icon, IconButton, InputLabel, MenuItem, Select, Tooltip } from "@mui/material";

import { Draggable, Droppable } from "react-beautiful-dnd";
import PromptMessageItem from "./PromptMessageItem";
import { MESSAGE_TYPE } from "../../../../constants";

/**
 * All informations about a message
 * @param {*} param0
 * @returns
 */
const MessageInformations = ({
	list: id,
	state,
	message,
	messages,
	updateMessage,
	deleteMessage,
	deletePromptPart
}) => {
	const [isVisualizing, setIsVisualizing] = useState(false);

	const messageList = state[id];

	if (!messageList) return null;
	// Preview message (assemble all the prompt parts)
	const previewMessage = messageList.map(item => {
		return (
			<Tooltip title={item.name} placement="top">
				<span className="promptPartPreviewHover">{item.prompt}</span>
			</Tooltip>
		);
	});

	const hasSystemMessage = messages.find(message => message.type === "system");

	/* Delete the prompt */
	const deletePrompt = index => {
		deletePromptPart(index, id);
	};

	return (
		<MDBox style={{ width: "100%", overflowY: "auto", position: "relative" }}>
			{/* Top bar */}
			<MDBox
				mb={1}
				display="flex"
				flexDirection="row"
				alignItems="center"
				justifyContent="space-between"
				// sticky bar
				style={{
					position: "sticky",
					boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
					top: 0,
					zIndex: 5,
					minHeight: "6vh"
				}}
				bgColor="white"
				p={1}
			>
				<MDTypography variant="h5">
					{id === "system_message"
						? t("PROMPT.PROCESS.MESSAGE.systemMessage")
						: t("PROMPT.PROCESS.MESSAGE.message")}
				</MDTypography>
				<MDBox flex="1" display="flex" flexDirection="row" alignItems="center" justifyContent="end">
					<FormControl>
						<InputLabel id="select-label">{t("PROMPT.PROCESS.MESSAGE.messageType")}</InputLabel>
						<Select
							disabled={id === "system_message"}
							labelId="select-label"
							id="select"
							label="type"
							value={message.type}
							onChange={e => {
								updateMessage(id, { type: e.target.value });
							}}
						>
							{MESSAGE_TYPE.map((item, index) => {
								return (
									<MenuItem
										key={index}
										value={item.code}
										disabled={hasSystemMessage && item.code === "system"}
									>
										<MDBox display="flex" flexDirection="row" alignItems="center">
											<Icon>{item.icon}</Icon>
											<MDTypography sx={{ ml: 1 }} variant="caption">
												{item.label}
											</MDTypography>
										</MDBox>
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
					<Tooltip title={t("SETTINGS.preview")} placement="top">
						<IconButton color="dark" onClick={() => setIsVisualizing(!isVisualizing)}>
							{isVisualizing ? <Icon>visibility_off</Icon> : <Icon>visibility</Icon>}
						</IconButton>
					</Tooltip>
					<Tooltip title={t("SETTINGS.delete")} placement="top">
						<IconButton
							disabled={id === "system_message"}
							color="dark"
							onClick={() => {
								deleteMessage(id);
							}}
						>
							<Icon>delete</Icon>
						</IconButton>
					</Tooltip>
				</MDBox>
			</MDBox>
			{/* Vizualizing preview */}
			{isVisualizing && (
				<MDBox m={1} p={1} borderRadius="md" bgColor="light">
					<MDTypography
						variant="body2"
						fontSize="small"
						style={{
							whiteSpace: "pre-wrap"
						}}
					>
						{previewMessage}
					</MDTypography>
				</MDBox>
			)}
			{/* Template parts */}
			<MDBox m={1} display="flex" flexDirection="row">
				<MDBox flex="1">
					<Droppable key={id} droppableId={id}>
						{(provided, snapshot) => (
							<MDBox
								mr={0.5}
								ref={provided.innerRef}
								isDraggingOver={snapshot.isDraggingOver}
								p={1}
								borderRadius="md"
								bgColor="light"
								style={{ minHeight: "8vh" }}
							>
								{/* Display prompt */}
								{messageList.length === 0 && (
									<MDBox
										display="flex"
										justifyContent="center"
										alignItems="center"
										style={{
											height: "8vh"
										}}
									>
										<MDTypography variant="body2" fontSize="medium">
											{t("PROMPT.PROCESS.MESSAGE.dragAndDropMessage")}
										</MDTypography>
									</MDBox>
								)}
								{messageList.map((item, index) => {
									return (
										<Draggable key={item.id} draggableId={item.id} index={index}>
											{(provided, snapshot) => (
												<MDBox>
													<MDBox
														bgColor="white"
														borderRadius="md"
														mb={1}
														shadow="md"
														p={1}
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														isDragging={snapshot.isDragging}
														style={provided.draggableProps.style}
														display="flex"
														flexDirection="column"
														alignItems="stretch"
													>
														<PromptMessageItem
															prompt={item}
															index={index}
															deletePrompt={deletePrompt}
														/>
													</MDBox>
												</MDBox>
											)}
										</Draggable>
									);
								})}
								{provided.placeholder}
							</MDBox>
						)}
					</Droppable>
				</MDBox>
			</MDBox>
		</MDBox>
	);
};

export default MessageInformations;

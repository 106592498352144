import { Autocomplete, Chip, Tooltip } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import i18n from "i18n";
import lod_ from "lodash";
import { useState } from "react";
import WhiteListItem from "./WhiteListItem";

const InputNumber = ({
	label,
	value,
	onChange,
	item = {},
	path,
	listOfApis = [],
	disabled,
	error,
	InputProps = {},
	...rest
}) => {
	const [viewInvalid, setViewInvalid] = useState("");

	let isArray = item.isArray || false;
	let whitelist = item.whitelist || [];

	item.type = "number";

	return (
		<MDBox mt={1} {...rest}>
			{isArray ? (
				<Tooltip
					open={viewInvalid === path}
					title={i18n.t("FORMS.whitelistInvalidList")}
					placement="bottom"
					disableFocusListener
					disableHoverListener
					disableTouchListener
				>
					<Autocomplete
						disabled={disabled}
						value={lod_.isArray(value) ? value : []}
						onChange={(event, newValue) => {
							if (lod_.isEmpty(whitelist)) {
								const numericValues = newValue.map(val => parseFloat(val));
								onChange(numericValues);
							} else {
								const numericValuesWhite = newValue.filter(val => whitelist.includes(val));
								if (numericValuesWhite.length === newValue.length) {
									onChange(numericValuesWhite);
								} else {
									setViewInvalid(path);
									setTimeout(() => {
										setViewInvalid("");
									}, 3000);
								}
							}
						}}
						multiple
						id="tags-filled"
						options={whitelist}
						freeSolo
						renderTags={(tags, getTagProps) => {
							return tags.map((option, index) => {
								return <Chip label={option} {...getTagProps({ index })} />;
							});
						}}
						renderInput={params => <MDInput {...params} type="number" label={label} />}
					/>
				</Tooltip>
			) : (
				<WhiteListItem
					label={label}
					value={value}
					onChange={onChange}
					item={item}
					listOfApis={listOfApis}
					disabled={disabled}
					error={error}
					InputProps={InputProps}
				/>
			)}
		</MDBox>
	);
};
export default InputNumber;

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useRef, useEffect, useState, useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Line } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";

// Material Dashboard 2 PRO React helper functions
import gradientChartLine from "assets/theme/functions/gradientChartLine";

// GradientLineChart configurations
import configs from "components/Advanced/Charts/LineCharts/GradientLineChart/configs";

// Material Dashboard 2 PRO React base styles
import colors from "assets/theme/base/colors";

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
} from "chart.js";
import DownloadCSV from "components/Custom/DownloadCSV";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function GradientLineChart({ icon, title, description, height, chart, tableData }) {
	const chartRef = useRef(null);
	const [chartData, setChartData] = useState({ data: { labels: [], datasets: [] } });
	const { data, options } = chartData;

	const getBackgroundColor = color => {
		if (colors[color]) {
			return colors[color || "dark"].main;
		}
		if (typeof color !== "string") {
			return "#00d084";
		}
		return color;
	};

	useEffect(() => {
		const chartDatasets = chart.datasets
			? chart.datasets.map(dataset => ({
					...dataset,
					tension: 0,
					pointRadius: 0,
					borderWidth: 4,
					borderColor: getBackgroundColor(dataset.color),
					fill: true,
					maxBarThickness: 6,
					backgroundColor: gradientChartLine(
						chartRef.current.children[0],
						getBackgroundColor(dataset.color)
					)
				}))
			: [];

		setChartData(configs(chart.labels || [], chartDatasets, chart.options));
	}, [chart]);

	const renderChart = (
		<MDBox py={2} pr={2} pl={icon.component ? 1 : 2}>
			{title || description ? (
				<MDBox
					display="flex"
					justifyContent="space-between"
					px={description ? 1 : 0}
					pt={description ? 1 : 0}
				>
					<MDBox display="flex">
						{icon.component && (
							<MDBox
								width="4rem"
								height="4rem"
								bgColor={icon.color || "info"}
								variant="gradient"
								coloredShadow={icon.color || "info"}
								borderRadius="xl"
								display="flex"
								justifyContent="center"
								alignItems="center"
								color="white"
								mt={-5}
								mr={2}
							>
								<Icon fontSize="medium">{icon.component}</Icon>
							</MDBox>
						)}
						<MDBox mt={icon.component ? -2 : 0}>
							{title && <MDTypography variant="h6">{title}</MDTypography>}
							<MDBox mb={2}>
								<MDTypography component="div" variant="button" color="text">
									{description}
								</MDTypography>
							</MDBox>
						</MDBox>
					</MDBox>
					<MDBox mt={icon.component ? -2 : 0}>
						<DownloadCSV data={tableData} title={title} />
					</MDBox>
				</MDBox>
			) : null}
			{useMemo(
				() => (
					<MDBox ref={chartRef} sx={{ height }}>
						<Line data={data} options={options} />
					</MDBox>
				),
				[chartData, height]
			)}
		</MDBox>
	);

	return title || description ? <Card>{renderChart}</Card> : renderChart;
}

// Setting default values for the props of GradientLineChart
GradientLineChart.defaultProps = {
	icon: { color: "info", component: "" },
	title: "",
	description: "",
	height: "19.125rem"
};

export default GradientLineChart;

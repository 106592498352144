/* eslint-disable import/no-extraneous-dependencies */
import {
	Divider,
	FormControl,
	Icon,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Tooltip
} from "@mui/material";
import ReactJson from "react-json-view";
import { t } from "i18next";

import MDBox from "components/Basics/MDBox";

import MDTypography from "components/Basics/MDTypography";
import InputBoolean from "components/Custom/LittleForm/Inputs/InputBoolean";
import InputString from "components/Custom/LittleForm/Inputs/InputString";
import ImportJsonDialog from "components/Custom/Dialogs/ImportJsonDialog";
import LlmModelList from "./LlmModel";

/**
 * Display all the informations about the process
 * @param {*} param0
 * @returns
 */
const ProcessInformations = ({
	onChangeProcess,
	process,
	step,
	onChangeStep,
	llmModels,
	llmModelProvider
}) => {
	const getJSONValue = value => {
		try {
			return JSON.parse(value);
		} catch {
			return value || {};
		}
	};

	const onChangeInputs = e => {
		let value = e.updated_src;
		onChangeStep("inputs", value);
	};

	const onChangeMappingOut = e => {
		let value = e.updated_src;
		onChangeStep("outputs.mappingOut", value);
	};

	return (
		<MDBox p={2} flex="1" style={{ overflowY: "auto" }}>
			{/* Infos */}
			<MDTypography variant="h5" color="textSecondary" gutterBottom>
				{t("PROMPT.PROCESS.processInfos")}
			</MDTypography>
			<InputString
				label={
					<div>
						{t("PROMPT.PROCESS.processCode")}&nbsp;<span style={{ color: "red" }}>*</span>
					</div>
				}
				value={process.code}
				onChange={value => onChangeProcess("code", value)}
			/>
			<InputString
				label={
					<div>
						{t("PROMPT.PROCESS.processName")}&nbsp;<span style={{ color: "red" }}>*</span>
					</div>
				}
				value={process.name}
				onChange={value => onChangeProcess("name", value)}
			/>
			<InputString
				label={t("PROMPT.PROCESS.processDescription")}
				value={process.description}
				onChange={value => onChangeProcess("description", value)}
			/>
			<Divider />

			{/* Inputs */}
			<MDTypography variant="h5" color="textSecondary">
				{t("PROMPT.PROCESS.processInputs")}
			</MDTypography>
			<InputBoolean
				label={t("PROMPT.PROCESS.addMessageHistory")}
				value={step.addHistory}
				onChange={value => {
					onChangeStep("addHistory", value);
				}}
			/>
			<MDBox
				mt={1}
				p={1}
				sx={{
					width: "100%",
					border: "1px solid #d2d6da",
					borderRadius: "5px"
					// overflow: "hidden"
				}}
				display="flex"
				alignItems="start"
				justifyContent="space-between"
			>
				<ReactJson
					src={getJSONValue(step.inputs)}
					name={null}
					style={{
						fontSize: "medium"
					}}
					enableClipboard={false}
					displayDataTypes={false}
					onEdit={onChangeInputs}
					onAdd={onChangeInputs}
					onDelete={onChangeInputs}
				/>
				<ImportJsonDialog
					component={
						<Tooltip title={t("COMPONENT.IMPORT_JSON.title")} placement="top">
							<IconButton size="small">
								<Icon>upload</Icon>
							</IconButton>
						</Tooltip>
					}
					onConfirm={({ value }) => {
						onChangeStep("inputs", JSON.parse(value));
					}}
				/>
			</MDBox>
			<Divider />

			{/* Outputs */}
			<MDTypography variant="h5" color="textSecondary">
				{t("PROMPT.PROCESS.processOutput")}
			</MDTypography>
			<InputBoolean
				label={t("PROMPT.PROCESS.saveProcessLogs")}
				value={step.log}
				onChange={value => {
					onChangeStep("log", value);
				}}
			/>
			<InputBoolean
				label={t("PROMPT.PROCESS.jsonOutput")}
				value={step.outputs.isJson}
				onChange={value => {
					onChangeStep("outputs.isJson", value);
				}}
			/>
			<InputString
				label={t("PROMPT.PROCESS.mappingKey")}
				value={step.outputs.mappingKey}
				onChange={value => onChangeStep("outputs.mappingKey", value)}
			/>
			<MDBox
				mt={1}
				p={1}
				sx={{
					width: "100%",
					border: "1px solid #d2d6da",
					borderRadius: "5px"
				}}
				display="flex"
				alignItems="start"
				justifyContent="space-between"
			>
				<ReactJson
					src={getJSONValue(step.outputs.mappingOut)}
					name={null}
					style={{
						fontSize: "medium"
					}}
					enableClipboard={false}
					displayDataTypes={false}
					onEdit={onChangeMappingOut}
					onAdd={onChangeMappingOut}
					onDelete={onChangeMappingOut}
				/>
				<ImportJsonDialog
					component={
						<Tooltip title={t("COMPONENT.IMPORT_JSON.title")} placement="top">
							<IconButton size="small">
								<Icon>upload</Icon>
							</IconButton>
						</Tooltip>
					}
					onConfirm={({ value }) => {
						onChangeStep("outputs.mappingOut", JSON.parse(value));
					}}
				/>
			</MDBox>
			<Divider />

			{/* Models */}
			<MDTypography variant="h5" color="textSecondary">
				{t("PROMPT.PROCESS.llmModels")}
			</MDTypography>
			<MDBox sx={{ width: "100%" }}>
				<FormControl sx={{ mt: 1, width: "100%" }}>
					<InputLabel id="select-modelCode-label">
						<div>
							{t("PROMPT.PROCESS.llmModel")}&nbsp;<span style={{ color: "red" }}>*</span>
						</div>
					</InputLabel>
					<Select
						labelId="select-modelCode-label"
						id="select-modelCode"
						label={
							<div>
								{t("PROMPT.PROCESS.llmModel")}&nbsp;<span style={{ color: "red" }}>*</span>
							</div>
						}
						value={step.modelCode}
						onChange={e => onChangeStep("modelCode", e.target.value)}
					>
						{llmModels.map((model, index) => {
							return (
								<MenuItem key={index} value={model.code}>
									<LlmModelList
										model={model}
										dbModel={llmModelProvider.find(
											provider =>
												provider.code === model.provider || provider.code === model.modelProvider
										)}
									/>
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
			</MDBox>
			<MDBox sx={{ width: "100%" }}>
				<FormControl sx={{ mt: 1.5, width: "100%" }}>
					<InputLabel id="select-fallBackModelCode-label">
						{t("PROMPT.PROCESS.fallbackLlmModel")}
					</InputLabel>
					<Select
						labelId="select-fallBackModelCode-label"
						id="select-fallBackModelCode"
						label={t("PROMPT.PROCESS.fallbackLlmModel")}
						value={step.fallBackModelCode}
						onChange={e => onChangeStep("fallBackModelCode", e.target.value)}
					>
						{llmModels
							.filter(m => m.code !== step.modelCode)
							.map((model, index) => {
								return (
									<MenuItem key={index} value={model.code}>
										<LlmModelList
											model={model}
											dbModel={llmModelProvider.find(
												provider =>
													provider.code === model.provider || provider.code === model.modelProvider
											)}
										/>
									</MenuItem>
								);
							})}
					</Select>
				</FormControl>
			</MDBox>
		</MDBox>
	);
};

export default ProcessInformations;

import MDButton from "components/Basics/MDButton";
import MDInput from "components/Basics/MDInput";
import { t } from "i18next";
import { useEffect, useState } from "react";

const { Dialog, DialogContent, DialogTitle, DialogActions } = require("@mui/material");

/**
 * Dialog displayed at the end of signature configuration to complete the creation
 * @param {*} param0
 * @returns
 */
const SignatureInformations = ({ open, onClose, onSave, html }) => {
	const [code, setCode] = useState("");
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");

	const valid = code && name;

	useEffect(() => {
		if (open) {
			setCode("");
			setName("");
			setDescription("");
		}
	}, [open]);

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
			<DialogTitle>{t("CHANNEL.SIGNATURE.signatureInfo")}</DialogTitle>
			<DialogContent>
				<MDInput
					fullWidth
					sx={{ mt: 1 }}
					label={t("SETTINGS.LABELS.code")}
					value={code}
					onChange={e => setCode(e.target.value)}
				/>
				<MDInput
					fullWidth
					sx={{ mt: 1 }}
					label={t("SETTINGS.PAGES.name")}
					value={name}
					onChange={e => setName(e.target.value)}
				/>
				<MDInput
					fullWidth
					sx={{ mt: 1 }}
					label={t("SETTINGS.SKILLS.description")}
					value={description}
					onChange={e => setDescription(e.target.value)}
				/>
			</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="info" onClick={onClose}>
					{t("SETTINGS.cancel")}
				</MDButton>
				<MDButton
					variant="contained"
					color="info"
					disabled={!valid}
					onClick={() => {
						onSave({ code, name, description, html });
					}}
				>
					{t("SETTINGS.add")}
				</MDButton>
			</DialogActions>
		</Dialog>
	);
};

export default SignatureInformations;

/* eslint-disable no-underscore-dangle */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/**
 * #######################################################@
 *
 * Skills chart settings
 *
 * #######################################################@
 */
import "./style.css";

import {
	Autocomplete,
	Chip,
	DialogActions,
	DialogContent,
	Divider,
	Icon,
	IconButton,
	ListItemButton,
	ListItemIcon,
	Menu,
	MenuItem,
	Modal,
	TextField
} from "@mui/material";
import { useState, useEffect } from "react";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import { t } from "i18next";
import lod_ from "lodash";

import { useDispatch } from "react-redux";

import { deleteFileFromPath } from "helpers/s3";
import { generateRandomCode, slugify } from "helpers/utilities";
import { socket } from "redux-react/middleware/ws";
import FormAction from "redux-react/actions/formAction";
import { display } from "redux-react/reducers/snackBarReducer";
import MDTypography from "components/Basics/MDTypography";
import MDInput from "components/Basics/MDInput";
import InputBoolean from "components/Custom/LittleForm/Inputs/InputBoolean";

import Alternative from "./components/Alternative";
import { ANSWERS_FORMAT, DOCUMENT_TYPE, TYPE } from "./constants";
import RightPannel from "./components/RightPannel";
import TipBar from "./components/TipBar";
import ChannelTag from "./components/ChannelTag";
import ChannelOptionRender from "./components/ChannelOptionRender";

// First element in the list to select all channels
const ALL_CHANNELS = {
	code: "ALL",
	name: t("COMPONENT.ANSWERTYPE.allChannels"),
	description: t("COMPONENT.ANSWERTYPE.allChannels"),
	icon: "message"
};

/**
 * Dialog to create or edit an answer type
 * @returns
 */
export default function AnswerTypeDialog({
	open,
	dictionary,
	handleClose,
	handleSave,
	allowedChannels,
	channelType,
	channels,
	answerItem: answerItemCode = null
}) {
	const dispatch = useDispatch();

	/**
	 * Use to store new attachments.
	 * If user close the dialog without saving, we will delete the attachments
	 */
	const [temporaryAttachments, setTemporaryAttachments] = useState([]);

	// Boolean variables (active answer / display answer in cockpit)
	const [active, setActive] = useState(true);
	const [cockpit, setCockpit] = useState(true);

	// Identification variables
	const [code, setCode] = useState("");
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");

	// Categories variables
	const [categories, setCategories] = useState([]);
	const [categoriesInput, setCategoriesInput] = useState("");
	const [selectedCategories, setSelectedCategories] = useState([]);

	// Editing element (if we are editing an existing answer)
	const [editingElement, setEditingElement] = useState({});

	// Channels variables
	const [channelsList, setChannelsList] = useState([]);
	const [selectedChannels, setSelectedChannels] = useState([ALL_CHANNELS]);

	// Selected alternative
	const [selectedAlternative, setSelectedAlternative] = useState(0);

	// Right menu pannel
	const [rightMenuPannel, setRightMenuPannel] = useState({
		open: false
	});

	// Add a single empty item for the first alternative
	const [alternatives, setAlternatives] = useState([{}]);
	// Advanced settings menu
	const [alternativeOptionMenu, setAlternativeOptionMenu] = useState({ open: null });

	// Languages
	const [languages, setLanguages] = useState([]);

	// Loading
	const [loading, setLoading] = useState(true);

	/**
	 * Add a new attachment to the temporary attachments
	 * Called by the child component Alternative
	 * @param {Object} attachment
	 * @param {String} alternativeIndex
	 */
	const addNewAttachment = (attachment, alternativeIndex) => {
		setTemporaryAttachments(prev => {
			let newAttachments = lod_.cloneDeep(prev);
			newAttachments.push({ attachment, alternativeIndex });
			return newAttachments;
		});
	};

	/**
	 * Delete all temporary attachments
	 * @param {String?} indexToDelete delete attachments for one alternative (by index)
	 * if nothing pass, delete all temporary attachemnts
	 */
	const clearTemporaryAttachments = async (indexToDelete = null) => {
		// Clear all attachments
		for (let item of temporaryAttachments) {
			let { attachment, alternativeIndex } = item;
			// Delete only if
			// - indexToDelete is null
			// - indexToDelete is equal to the alternativeIndex (when user delete an alternative)
			if (!indexToDelete || indexToDelete === alternativeIndex) {
				await deleteFileFromPath(attachment.key);
			}
		}
	};

	/**
	 * If user can click on submit button
	 * @returns boolean
	 */
	const canUserSave = () => {
		if (!name?.trim()) return false;
		if (!description?.trim()) return false;
		if (selectedChannels.length === 0) return false;
		if (alternatives.length < 1) return false;
		// check is there is at least one alternative with a text
		let hasText = alternatives.find(item => {
			let keys = Object.keys(item);
			let hasText = keys.find(key => item[key].text !== null && item[key].text?.trim() !== "");
			return Boolean(hasText);
		});
		if (!hasText) return false;

		return true;
	};

	/* Close modale */
	const close = async (e, reason) => {
		await clearTemporaryAttachments();
		handleClose();
	};

	/* Submit answer type */
	const submit = () => {
		// Clean alternatives
		let cleanedAlternatives = [];
		alternatives.map(item => {
			let isActive = false;

			// Check if there is at least one text in the alternative
			let keys = Object.keys(item);
			keys.map(key => {
				let object = item[key];
				if (object.text && object.text.trim() !== "") {
					isActive = true;
				}

				if (channelType === "ticket") {
					// Check for subject & attachments
					object.subject = object.subject || "";
					object.attachments = object.attachments || [];
				}
			});

			if (isActive) {
				cleanedAlternatives.push(item);
			}
		});

		let format = ANSWERS_FORMAT.richtext;
		switch (channelType) {
			case TYPE.review:
				format = ANSWERS_FORMAT.richtext;
				break;
			case TYPE.ticket:
				format = ANSWERS_FORMAT.htmltext;
				break;
			case TYPE.conversational:
				format = ANSWERS_FORMAT.richtext;
				break;
			default:
				format = ANSWERS_FORMAT.richtext;
				break;
		}

		// Create answer object
		let answer = {
			documentType: DOCUMENT_TYPE.answerType,
			code,
			format,
			type: channelType,
			channelType,
			//
			name,
			description,
			//
			channelsCode: selectedChannels.map(item => item.code),
			content: cleanedAlternatives,
			//
			categories: selectedCategories,
			version: "2.0",
			active,
			cockpit
		};

		let options = {
			// - If we have attachments or not in the answer
			attachments: false, // For the furure
			// - If we have CUSTOM variables in the answer
			custom: false,
			// - If we have API variables in the answer
			api: false,
			// - SimpleText : format === "htmltext" || format === "richtext"
			simpleText: true // For the review, it will be always true because the format is richtext
		};

		let stringifiedContent = JSON.stringify(answer.content);
		// Check for custom variables: if there is {{{ CUSTOM in the stringified content
		if (stringifiedContent.includes("{{{ CUSTOM")) {
			options.custom = true;
		}

		// Check for API variables: if there is {{{ API in the stringified content
		if (stringifiedContent.includes("{{{ API")) {
			options.api = true;
		}

		answer.options = options;

		const onSuccess = res => {
			handleSave(res?.item, Boolean(answerItemCode));
		};

		let data = { values: answer, target: "answerItem", unique: [] };

		if (answerItemCode) {
			// Update answer item
			dispatch(FormAction.updateItem(editingElement._id, data, onSuccess));
		} else {
			// Create answer item
			dispatch(FormAction.addItemEmpty(data, onSuccess));
		}
	};

	/* Delete an alternative by index */
	const deleteAlternative = index => {
		let alternativesClone = lod_.cloneDeep(alternatives);
		alternativesClone = alternativesClone.filter((item, indexItem) => indexItem !== index);
		setAlternatives(alternativesClone);
		setSelectedAlternative(index > 0 ? index - 1 : 0);
		clearTemporaryAttachments(index);
	};

	/**
	 * Duplicate an alternative by index
	 * @param {Int} index The index of the alternative
	 */
	const duplicateAlternative = index => {
		let alternativesClone = lod_.cloneDeep(alternatives);
		let alternative = lod_.cloneDeep(alternativesClone[index]);
		alternativesClone.push(alternative);
		setAlternatives(alternativesClone);
	};

	/**
	 * Change the value of a field for an alternative
	 * @param {Int} index The index of the alternative
	 * @param {String} language The language of the alternative
	 * @param {String} path The path of the value to change
	 * @param {*} value The new value
	 */
	const changeAlternativePath = (index, language, path, value) => {
		setAlternatives(prevAlternatives => {
			// Create a deep copy of the alternatives
			const newAlternatives = lod_.cloneDeep(prevAlternatives);
			// Check if the index exists in the array, if not, create it
			if (!newAlternatives[index]) {
				newAlternatives[index] = {};
			}

			// Set the value in the new alternatives
			lod_.set(newAlternatives, `[${index}].${language}.${path}`, value);

			return newAlternatives;
		});
	};

	/**
	 * Get only the channels that are allowed by the channelType
	 */
	const setChannelsByChanneType = () => {
		let filteredChannels = [];

		// 1- Filter channels by channelType
		for (let channel of channels) {
			let isAllowed = allowedChannels.find(
				item => item.type === channel.type && item.subType === channel.subType
			);
			if (isAllowed) {
				channel.channelType = isAllowed.channelType;
				filteredChannels.push(channel);
			}
		}

		// 2- Add extra elements
		let extra = [
			ALL_CHANNELS,
			{
				type: "divider"
			}
		];

		let completeChannels = [...extra, ...filteredChannels];

		// 3- Set the channels list
		setChannelsList(completeChannels);

		// 4- If we are editing an element, set the selected channels
		if (editingElement && !lod_.isEmpty(editingElement)) {
			let channels = completeChannels.filter(channel =>
				editingElement?.channelsCode?.includes(channel.code)
			);
			setSelectedChannels(channels);
		}
	};

	/**
	 * Select / add a category in the Autocomplete component
	 * Send a WS event to add the category in the database
	 * @param {String} option the category to add
	 */
	const selectOption = option => {
		let find = categories.find(item => item === option);
		if (!find) {
			// Add category to database
			socket.emit("answer_item_add_category", { category: option });
		}
	};

	/**
	 * Result of the selectOption function, sended by the WS
	 * Add the category to the categories list
	 * @param {Object} { category } The category to add
	 */
	const selectOptionResult = ({ category }) => {
		setCategories(prev => {
			return [...prev, category];
		});
	};

	/**
	 * Delete a category from the categories list in the Autocomplete component
	 * Send a WS event to delete the category from the database
	 * @param {String} category the category to delete
	 */
	const deleteCategory = category => {
		socket.emit("answer_item_delete_category", { category });
	};

	/**
	 * Result of the deleteCategory function, sended by the WS
	 * Delete the category from the categories list
	 * @param {Object} { category } The category to delete
	 */
	const deleteCategoryResult = ({ category }) => {
		let newCategories = lod_.cloneDeep(categories).filter(item => item !== category);
		setCategories(prev => {
			return newCategories;
		});
	};

	/**
	 * Load the answer type
	 */
	useEffect(() => {
		setLoading(true);
		// Reset all var
		setActive(true);
		setCockpit(true);
		setName("");
		setDescription("");
		setSelectedChannels([ALL_CHANNELS]);
		setAlternatives([{}]);
		setEditingElement({});
		setSelectedCategories([]);
		setTemporaryAttachments([]);

		setSelectedAlternative(0);

		// Reset UX
		setRightMenuPannel({ open: false });

		// Get categories
		dispatch(
			FormAction.getItemsFromCollection(
				"answerItem",
				{
					query: {
						documentType: "answerItemCategories"
					}
				},
				res => {
					let result = res.items;
					if (result?.length > 0 && result[0]?.categories) {
						setCategories(result[0].categories);
					}
				}
			)
		);

		// Get all possible languages
		dispatch(
			FormAction.getItemsFromCollection(
				"language",
				{
					query: {
						label: { $in: [null, false] }
					},
					sort: {
						default: -1
					}
				},
				res => {
					let result = res.items;
					setLanguages(result);
				}
			)
		);

		// For each channel, check if it is in the allowed channels'list by channel.type && channel.subType
		setChannelsByChanneType();

		if (open) {
			if (answerItemCode) {
				// If we are editing an element, get the element from the database
				dispatch(
					FormAction.getItemsFromCollection(
						"answerItem",
						{
							query: {
								code: answerItemCode,
								active: { $in: [true, false, null] }
							}
						},
						res => {
							let items = res?.items ?? [];

							if (items.length > 0) {
								let item = items[0];

								let channels = channelsList.filter(channel =>
									item.channelsCode.includes(channel.code)
								);

								setEditingElement(item);

								setCode(item.code);
								setActive(item.active ?? false);
								setCockpit(item.cockpit ?? false);
								setName(item.name);
								setDescription(item.description);
								setSelectedChannels(channels);
								setAlternatives(item.content);
								setSelectedCategories(item.categories ?? []);

								setLoading(false);
							} else {
								dispatch(
									display({
										message: t("COMPONENT.ANSWERTYPE.cannotLoadAnswerType"),
										type: "error"
									})
								);
								handleClose();
							}
						}
					)
				);
			} else {
				// If we are creating a new element, generate a new code
				if (name.trim()) {
					setCode(`${slugify(name)}_${generateRandomCode()}`);
				} else {
					setCode(`${generateRandomCode(4)}_${generateRandomCode(4)}_${generateRandomCode(4)}`);
				}
				setLoading(false);
			}
		}
	}, [open]);

	/**
	 * WS events
	 */
	useEffect(() => {
		socket.on("answer_item_delete_category_result", deleteCategoryResult);
		socket.on("answer_item_add_category_result", selectOptionResult);

		return () => {
			socket.off("answer_item_delete_category_result", deleteCategoryResult);
			socket.off("answer_item_add_category_result", selectOptionResult);
		};
	}, [categories]);

	if (loading) {
		return null;
	}

	return (
		<Modal open={open} onClose={close}>
			<MDBox
				p={1}
				sx={{
					height: "100%",
					width: "100%",
					display: "flex",
					flexDirection: "row"
				}}
			>
				<MDBox
					bgColor="white"
					borderRadius="md"
					style={{
						transition: "all 0.5s ease",
						overflow: "auto",
						width: rightMenuPannel.open ? "75%" : "100%"
					}}
					display="flex"
					flexDirection="column"
				>
					<DialogContent
						sx={{
							display: "flex",
							flexDirection: "column"
						}}
					>
						{/* Display tip bar on top of dialog */}
						<TipBar />

						{/* Top menu */}
						<MDBox>
							{/* Answer's name */}
							<MDBox display="flex" flexDirection="row" alignItems="stretch">
								<TextField
									sx={{ flex: 2 }}
									fullWidth
									placeholder={t("COMPONENT.ANSWERTYPE.answerName")}
									value={name}
									onChange={e => setName(e.target.value)}
									InputProps={{
										// disableUnderline: true,
										style: {
											fontSize: "1.5rem",
											color: "#344767",
											fontWeight: "700",
											letterSpacing: "0.00735em",
											opacity: "1"
										}
									}}
								/>
								{/* Active / Cockpit boolean */}
								<MDBox flex="1" display="flex" flexDirection="row" alignItems="stretch">
									<InputBoolean
										label={t("COMPONENT.ANSWERTYPE.active")}
										value={active}
										onChange={val => {
											setActive(val);
										}}
										InputProps={{
											sx: { ml: 1, flex: 1 },
											style: {
												marginTop: 0,
												whiteSpace: "nowrap"
											}
										}}
									/>
									<InputBoolean
										label={t("COMPONENT.ANSWERTYPE.displayInCockpit")}
										value={cockpit}
										onChange={val => {
											setCockpit(val);
										}}
										InputProps={{
											sx: { ml: 1, flex: 1 },
											style: {
												marginTop: 0,
												whiteSpace: "nowrap"
											}
										}}
									/>
								</MDBox>
							</MDBox>
							{/* Answer's description */}
							<MDInput
								sx={{ mt: 1 }}
								placeholder={t("COMPONENT.ANSWERTYPE.description")}
								fullWidth
								value={description}
								onChange={e => setDescription(e.target.value)}
								InputProps={{
									style: {
										fontSize: "1rem"
									}
								}}
							/>
							{/* Bottom bar */}
							<MDBox display="flex" flexDirection="row" alignItems="stretch" sx={{ mt: 1 }}>
								{/* Answer channels */}
								<Autocomplete
									sx={{ pt: 1, flex: 2 }}
									disableCloseOnSelect
									value={selectedChannels}
									multiple
									id="tags-filled"
									options={channelsList}
									getOptionLabel={option => {
										return option.name;
									}}
									getOptionDisabled={option => {
										if (option.type === "divider") {
											return true;
										}

										let isSelectAll = selectedChannels.find(item => item.code === "ALL");
										if (isSelectAll) {
											return Boolean(option.code !== "ALL");
										} else if (selectedChannels.length > 0) {
											return Boolean(option.code === "ALL");
										}

										return false;
									}}
									freeSolo
									onChange={(event, newValue) => {
										setSelectedChannels(newValue);
									}}
									renderOption={(props, option) => {
										if (option.type === "divider") {
											return <Divider />;
										}

										return <ChannelOptionRender props={props} option={option} />;
									}}
									renderTags={(tags, getTagProps) => {
										return tags.map((option, index) => {
											return (
												<ChannelTag
													key={index}
													option={option}
													getTagProps={getTagProps}
													index={index}
												/>
											);
										});
									}}
									renderInput={params => (
										<MDInput {...params} label={t("COMPONENT.ANSWERTYPE.channelsChoice")} />
									)}
								/>
								{/* Categories */}
								<MDBox
									flex="1"
									style={{
										overflowX: "auto"
									}}
								>
									<Autocomplete
										sx={{
											pt: 1,
											flex: 1,
											ml: 1,
											height: "100%",
											"& .MuiAutocomplete-inputRoot": {
												flexWrap: "nowrap",
												height: "100%"
											},
											"& .MuiChip-root": {
												whiteSpace: "nowrap"
											}
										}}
										disableCloseOnSelect
										value={selectedCategories}
										multiple
										id="categories-filled"
										options={categories}
										freeSolo
										onChange={(event, newValue, reason, { option }) => {
											if (reason === "createOption") {
												selectOption(option);
											}
											setSelectedCategories(newValue);
											setCategoriesInput("");
										}}
										renderTags={(tags, getTagProps) => {
											return (
												<div
													style={{
														display: "flex",
														overflowX: "auto"
													}}
												>
													{tags.map((option, index) => {
														return (
															<Chip
																key={index}
																label={option}
																{...getTagProps({ index })}
																style={{ whiteSpace: "nowrap" }}
															/>
														);
													})}
												</div>
											);
										}}
										inputValue={categoriesInput}
										renderOption={(props, option) => {
											return (
												<MenuItem
													{...props}
													sx={{ mt: 0.5 }}
													style={{
														display: "flex",
														justifyContent: "space-between",
														alignItems: "center"
													}}
												>
													<MDBox display="flex" alignItems="center">
														{option}
													</MDBox>
													<IconButton
														size="small"
														onClick={e => {
															e.stopPropagation();
															deleteCategory(option);
														}}
													>
														<Icon>delete</Icon>
													</IconButton>
												</MenuItem>
											);
										}}
										renderInput={params => (
											<MDInput
												placeholder={`${t("COMPONENT.ANSWERTYPE.categories")} ...`}
												style={{
													height: "100%"
												}}
												{...params}
												label={t("COMPONENT.ANSWERTYPE.categories")}
												onChange={e => setCategoriesInput(e.target.value)}
												onBlur={e => {
													if (e.target.value) {
														selectOption(e.target.value);
														setSelectedCategories([...selectedCategories, e.target.value]);
														setCategoriesInput("");
													}
												}}
											/>
										)}
									/>
								</MDBox>
							</MDBox>
						</MDBox>

						{/* Alternatives box */}
						{!channelType ? (
							<MDBox
								display="flex"
								flexDirection="column"
								alignItems="center"
								justifyContent="center"
								sx={{ height: "100%" }}
							>
								<MDTypography variant="h6">
									{t("COMPONENT.ANSWERTYPE.selectAtLeastOneChannel")}
								</MDTypography>
							</MDBox>
						) : (
							<MDBox
								flex="1"
								mt={3}
								sx={{ width: "100%" }}
								display="flex"
								flexDirection="row"
								alignItems="stretch"
								borderRadius="md"
								style={{
									overflow: "hidden",
									position: "relative",
									border: "1px solid rgb(232, 232, 238)"
								}}
							>
								<MDBox
									flex="1"
									bgColor="light"
									style={{
										position: "sticky",
										top: "0px",
										borderRight: "1px solid rgb(232, 232, 238)"
									}}
								>
									<MDBox display="flex" flexDirection="column" sx={{ height: "100%" }}>
										<MDBox
											flex="1"
											style={{
												overflowY: "auto"
											}}
										>
											{alternatives.map((item, index) => {
												return (
													<ListItemButton
														key={index}
														selected={selectedAlternative === index}
														onClick={() => {
															setSelectedAlternative(index);
															setRightMenuPannel({
																open: true,
																alternative: index,
																language: null,
																languages
															});
														}}
														sx={{
															"&.Mui-selected": {
																backgroundColor: "info.main",
																color: "white.main",
																"&:hover": {
																	backgroundColor: "info.main"
																}
															},
															"&.Mui-selected > *": {
																color: "white.main"
															},
															"&:hover": {
																backgroundColor: "info.light"
															},
															"&:hover > *": {
																color: "white.main"
															}
														}}
														display="flex"
														justifyContent="space-between"
													>
														<MDTypography
															flex="1"
															variant="body2"
															fontWeight="bold"
															sx={{ textTransform: "capitalize", whiteSpace: "nowrap" }}
														>
															{`Variante ${index + 1}`}
														</MDTypography>
														<ListItemIcon
															style={{
																justifyContent: "end"
															}}
														>
															<IconButton
																size="small"
																onClick={e => {
																	e.stopPropagation();
																	e.preventDefault();
																	setAlternativeOptionMenu({
																		open: e.currentTarget,
																		alternative: index
																	});
																}}
															>
																<Icon
																	style={{
																		color: selectedAlternative === index ? "white" : "black"
																	}}
																>
																	more_vert
																</Icon>
															</IconButton>
														</ListItemIcon>
													</ListItemButton>
												);
											})}

											<Menu
												anchorEl={alternativeOptionMenu.open}
												open={Boolean(alternativeOptionMenu.open)}
												onClose={() =>
													setAlternativeOptionMenu({
														open: null,
														alternative: null
													})
												}
											>
												<MenuItem disabled style={{ opacity: 1 }}>
													<MDTypography variant="caption">{`Variante ${alternativeOptionMenu.alternative + 1}`}</MDTypography>
												</MenuItem>

												<MenuItem
													disabled={alternatives.length >= 5}
													onClick={() => {
														setAlternativeOptionMenu({ open: null });
														duplicateAlternative(alternativeOptionMenu.alternative);
													}}
												>
													<Icon>copy</Icon>&nbsp;{t("SETTINGS.duplicate")}
												</MenuItem>

												<MenuItem
													disabled={alternatives.length <= 1}
													onClick={() => {
														setAlternativeOptionMenu({ open: null });
														deleteAlternative(alternativeOptionMenu.alternative);
													}}
												>
													<Icon>delete</Icon>&nbsp;{t("SETTINGS.delete")}
												</MenuItem>
											</Menu>
										</MDBox>

										<MDBox p={2} align="center">
											<MDButton
												sx={{ width: "100%" }}
												variant="contained"
												color="info"
												onClick={() => {
													let alternativesClone = lod_.cloneDeep(alternatives);
													alternativesClone.push({});
													setAlternatives(alternativesClone);
												}}
												disabled={alternatives.length >= 5}
											>
												{t("COMPONENT.ANSWERTYPE.newAlternative")}
											</MDButton>
										</MDBox>
									</MDBox>
								</MDBox>
								<MDBox
									flex="6"
									style={{
										overflowY: "auto"
									}}
								>
									<Alternative
										code={code}
										dictionary={dictionary}
										languages={languages}
										alternativeIndex={selectedAlternative}
										channelType={channelType}
										alternative={
											alternatives?.find((item, index) => index === selectedAlternative) ??
											alternatives[0]
										}
										rightMenuPannel={rightMenuPannel}
										setRightMenuPannel={setRightMenuPannel}
										onChangePath={(index, language, path, value) => {
											changeAlternativePath(index, language, path, value);
										}}
										addNewAttachment={addNewAttachment}
									/>
								</MDBox>
							</MDBox>
						)}
					</DialogContent>
					<DialogActions
						style={{
							position: "sticky",
							bottom: 0,
							backgroundColor: "white"
						}}
					>
						<MDButton variant="outlined" color="info" onClick={close}>
							{t("SETTINGS.cancel")}
						</MDButton>
						<MDButton disabled={!canUserSave()} variant="contained" color="info" onClick={submit}>
							{answerItemCode ? t("SETTINGS.edit") : t("SETTINGS.add")}
						</MDButton>
					</DialogActions>
				</MDBox>
				{/* Right pannel */}
				<RightPannel
					rightMenuPannel={rightMenuPannel}
					setRightMenuPannel={setRightMenuPannel}
					alternatives={alternatives}
					selectedAlternative={selectedAlternative}
					changeAlternativePath={changeAlternativePath}
					name={name}
					description={description}
					channelType={channelType}
				/>
			</MDBox>
		</Modal>
	);
}

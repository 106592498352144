import lod_ from "lodash";
import MDBox from "components/Basics/MDBox";
import MDButton from "components/Basics/MDButton";
import MDTypography from "components/Basics/MDTypography";
import MDInput from "components/Basics/MDInput";
import { FormControl, IconButton, InputLabel, MenuItem, Select } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { t } from "i18next";
import SettingsActions from "redux-react/actions/settingsActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";

/**
 * Right pannel link a dictionary
 * @param {*} param0
 * @returns
 */
const PannelDictionary = ({ values, handleInputChange }) => {
	const user = useSelector(state => state.user);
	const profile = useSelector(selectCurrentProfile);
	const language = profile?.language || user?.language || "FR";

	const dispatch = useDispatch();

	const [dictionaries, setDictionaries] = useState({});

	const getDictionaries = () => {
		dispatch(
			SettingsActions.getDictionaries(
				{
					query: {}
				},
				res => {
					setDictionaries(res.dictionaries);
				}
			)
		);
	};

	useEffect(() => {
		getDictionaries();
	}, []);

	return (
		<MDBox display="flex" flex="1">
			<div style={{ borderLeft: "1px solid #ccc", height: "100%", margin: "0 10px" }} />
			<MDBox display="flex" flexDirection="column" width="100%">
				<MDBox sx={{ mb: 1 }}>
					<MDTypography variant="h6">Parametrer le dictionnaire</MDTypography>

					<MDBox mt={2}>
						<FormControl fullWidth>
							<InputLabel id="select-label">Dictionnaire</InputLabel>
							<Select
								labelId="select-label"
								id="select"
								label="Dictionnaire"
								value={values?.config?.dictionary || ""}
								onChange={e => {
									let event = {
										target: {
											name: "config",
											value: {
												dictionary: e.target.value
											}
										}
									};
									handleInputChange(event);
								}}
							>
								{Object.keys(dictionaries).map((key, index) => {
									let dictionary = dictionaries[key];
									let name = dictionary.label[language.toLowerCase()];

									return (
										<MenuItem key={index} value={key}>
											{name ?? key}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</MDBox>
				</MDBox>
			</MDBox>
		</MDBox>
	);
};

export default PannelDictionary;

/* eslint-disable import/no-extraneous-dependencies */
import { Checkbox, Icon, IconButton, InputAdornment, ListItemButton, Tooltip } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import MDTypography from "components/Basics/MDTypography";
import ImportJsonDialog from "components/Custom/Dialogs/ImportJsonDialog";
import { t } from "i18next";
import { useState } from "react";
import ReactJson from "react-json-view";

const ProcessChoice = ({
	llmProcess,
	selectedDataset,
	onChangeDataset,
	selectedProcess,
	selectProcess,
	disabled
}) => {
	const [filter, setFilter] = useState("");

	return (
		<MDBox flex="1" style={{ overflowY: "auto", height: "100%", width: "100%" }}>
			<MDBox
				display="flex"
				style={{
					height: "100%"
				}}
			>
				<MDBox flex="3" display="flex" flexDirection="column">
					{/* Dataset */}
					<MDBox flex="3">
						<MDBox
							p={1}
							shadow="md"
							style={{
								height: "7vh"
							}}
							display="flex"
							alignItems="center"
						>
							<MDTypography variant="h5">
								{t("PROMPT.PLAYGROUND.RUN.CONFIG.entryDatas")}
							</MDTypography>
						</MDBox>
						<MDBox m={1} display="flex" alignItems="start" justifyContent="space-between">
							<ReactJson
								src={selectedDataset}
								name={null}
								style={{
									fontSize: "medium"
								}}
								enableClipboard={false}
								displayDataTypes={false}
								onEdit={onChangeDataset}
								onAdd={onChangeDataset}
								onDelete={onChangeDataset}
							/>
							<ImportJsonDialog
								component={
									<Tooltip title={t("COMPONENT.IMPORT_JSON.title")} placement="top">
										<IconButton size="small">
											<Icon>upload</Icon>
										</IconButton>
									</Tooltip>
								}
								onConfirm={({ value }) => {
									onChangeDataset({
										updated_src: JSON.parse(value)
									});
								}}
							/>
						</MDBox>
					</MDBox>
					{/* Options */}
					<MDBox flex="2">
						<MDBox
							p={1}
							shadow="md"
							style={{
								height: "7vh"
							}}
							display="flex"
							alignItems="center"
						>
							<MDTypography variant="h5">
								{t("PROMPT.PLAYGROUND.RUN.CONFIG.optionsTitle")}
							</MDTypography>
						</MDBox>
						<MDBox m={1}>
							<MDTypography variant="body2" fontSize="small">
								{t("PROMPT.PLAYGROUND.RUN.CONFIG.noOptionsAvailable")}
							</MDTypography>
						</MDBox>
					</MDBox>
				</MDBox>
				{/* Process */}
				<MDBox flex="2" shadow="md" style={{ overflowY: "auto", height: "100%", width: "100%" }}>
					<MDBox
						p={1}
						bgColor="white"
						shadow="md"
						style={{
							height: "7vh"
						}}
						display="flex"
						alignItems="center"
					>
						<MDInput
							size="small"
							fullWidth
							placeholder={t("PROMPT.PLAYGROUND.RUN.CONFIG.searchProcess")}
							value={filter}
							onChange={e => setFilter(e.target.value)}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											aria-label="clear"
											onClick={() => {
												setFilter("");
											}}
										>
											<Icon>close</Icon>
										</IconButton>
									</InputAdornment>
								)
							}}
						/>
					</MDBox>
					<MDBox>
						{llmProcess
							.filter(process => process.name.toLowerCase().includes(filter.toLowerCase()))
							.map((process, index) => {
								return (
									<MDBox
										key={index}
										display="flex"
										alignItems="center"
										style={{
											cursor: "pointer",
											userSelect: "none",
											width: "100%"
										}}
										pl={1}
										pr={1}
									>
										<ListItemButton onClick={() => !disabled && selectProcess(process.code)}>
											<Checkbox
												disabled={disabled}
												checked={selectedProcess.includes(process.code)}
											/>
											<MDTypography
												variant="body2"
												fontSize="small"
												style={{
													fontWeight: "bold"
												}}
											>
												{process.name}
											</MDTypography>
										</ListItemButton>
									</MDBox>
								);
							})}
					</MDBox>
				</MDBox>
			</MDBox>
		</MDBox>
	);
};

export default ProcessChoice;

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-curly-brace-presence */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import MDButton from "components/Basics/MDButton";

// Custom styles for the Configurator
import ConfiguratorRoot from "components/Advanced/Configurator/ConfiguratorRoot";

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setMiniSidenav, setDarkMode, setOpenConfigurator } from "context";
import { Menu, MenuItem } from "@mui/material";

import i18n from "i18n";
import { useDispatch, useSelector } from "react-redux";
import { api } from "redux-react/actions/api";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";
import { selectAssistants } from "redux-react/reducers/assistantsReducer";
import { display } from "redux-react/reducers/snackBarReducer";
import { setError } from "redux-react/reducers/errorReducer";
import ProfileActions from "redux-react/actions/profileActions";

function Configurator() {
	const reactDispatch = useDispatch();
	const profile = useSelector(selectCurrentProfile);
	const assistants = useSelector(selectAssistants);
	const user = useSelector(state => state.user);

	const [controller, dispatch] = useMaterialUIController();
	const { openConfigurator, miniSidenav, darkMode } = controller;

	const [menu, setMenu] = useState(null);
	const [menuWidth, setMenuWidth] = useState("auto");

	const openMenu = event => {
		setMenu(event.currentTarget);
		setMenuWidth(event.target.clientWidth);
	};
	const closeMenu = () => setMenu(null);
	const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);

	/**
	 * Menu to switch between assistants
	 */
	const renderMenu = (
		<Menu
			anchorEl={menu}
			open={Boolean(menu)}
			onClose={closeMenu}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center"
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "center"
			}}
		>
			{Object.keys(assistants)
				.filter(key => assistants[key].active)
				.map((key, index) => {
					return (
						<MenuItem
							key={index}
							style={{ width: menuWidth }}
							onClick={async () => {
								/**
								 * If the selected assistant is the same as the current one, do nothing
								 */
								if (assistants[key].assistantID === profile.selectedAssistant.assistantID) {
									return;
								}
								/**
								 * Change assistant call
								 */
								reactDispatch(
									display({
										message: `Chargement`,
										type: "info"
									})
								);
								reactDispatch(
									api({
										type: "selectProfile",
										url: "/api/v1/profiles/" + assistants[key].assistantID,
										onSuccess: res => {
											reactDispatch(ProfileActions.selectProfile(res, assistants, user));
											reactDispatch(
												display({
													message: `Connecté sur l'assistant ${assistants[key].name}`,
													type: "success"
												})
											);
											closeMenu();
											handleCloseConfigurator();
										},
										onFailure: err => {
											reactDispatch(setError(err.response?.data?.error ?? "none"));
										}
									})
								);
							}}
						>
							{assistants[key].name}
						</MenuItem>
					);
				})}
			{/* <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <MDTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </MDTypography>
      </MenuItem> */}
		</Menu>
	);

	// const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
	const handleDarkMode = () => setDarkMode(dispatch, !darkMode);
	return (
		<>
			{openConfigurator && (
				<div
					style={{
						height: "100%",
						width: "100%",
						backgroundColor: "#0000004a",
						position: "fixed",
						top: 0,
						left: 0,
						zIndex: 1200
					}}
					onClick={handleCloseConfigurator}
				></div>
			)}

			<ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
				<MDBox
					display="flex"
					justifyContent="space-between"
					alignItems="baseline"
					pt={4}
					pb={0.5}
					px={3}
				>
					<MDBox>
						<MDTypography variant="h5">{i18n.t("CONFIGURATOR.title")}</MDTypography>
						<MDTypography variant="body2" color="text">
							{i18n.t("CONFIGURATOR.subTitle")}
						</MDTypography>
					</MDBox>

					<Icon
						sx={({ typography: { size }, palette: { dark, white } }) => ({
							fontSize: `${size.lg} !important`,
							color: darkMode ? white.main : dark.main,
							stroke: "currentColor",
							strokeWidth: "2px",
							cursor: "pointer",
							transform: "translateY(5px)"
						})}
						onClick={handleCloseConfigurator}
					>
						close
					</Icon>
				</MDBox>

				<Divider />

				<MDBox pt={0.5} pb={3} px={3}>
					{/*
					 * Menu button open
					 */}
					<MDButton style={{ width: "100%" }} variant="contained" color="dark" onClick={openMenu}>
						{profile.selectedAssistant?.name}
						<Icon>{menu ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon>
					</MDButton>

					{/*
					 * Menu content
					 */}
					{renderMenu}
					{/* <MDBox display="flex" justifyContent="space-between" alignItems="center" lineHeight={1}>
          <MDTypography variant="h6">Sidenav Mini</MDTypography>
          <Switch checked={miniSidenav} onChange={handleMiniSidenav} />
        </MDBox>
        <Divider /> */}
					<MDBox
						sx={{ mt: 2 }}
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						lineHeight={1}
					>
						<MDTypography variant="h6">Light / Dark</MDTypography>

						<Switch checked={darkMode} onChange={handleDarkMode} />
					</MDBox>
				</MDBox>
			</ConfiguratorRoot>
		</>
	);
}

export default Configurator;

import MDBox from "components/Basics/MDBox";
import MDButton from "components/Basics/MDButton";

const DisplayItem = (suggestedValue, option, onChangeCountry) => {
	return (
		<MDButton
			onClick={() => onChangeCountry(null, option?.code, "selectOption")}
			style={{ width: "100%" }}
		>
			<MDBox style={{ display: "flex", alignItems: "center" }}>
				<span fontWeight="bold" style={{ font: "inherit" }}>
					{option?.code}
				</span>
				<span style={{ marginRight: "5px", marginLeft: "5px" }}>-</span>
				<img src={option?.logoURL} alt="Filter_flag" width="20" height="20" />
			</MDBox>
		</MDButton>
	);
};

export default DisplayItem;
